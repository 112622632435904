import * as constants from './actionTypes';
import { fromJS } from 'immutable';

const defaultState=fromJS({
  psSoip:null,
});

const ReducreFn=(state=defaultState,action)=>{
    switch(action.type){
        case constants.SET_PS_SOIP:
            return state.set('psSoip',action.data);
        default:
            return state;    
        
    }
}

export default ReducreFn