import { combineReducers } from 'redux-immutable';
import {reducre as deviceTypesReducre} from '../components/deviceTypes/store'

const defaultCombine = combineReducers({

    deviceTypes:deviceTypesReducre
})


export default defaultCombine


