import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next' //语言包
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import cookie from 'react-cookies'

import { Avatar, Card, Dropdown, Menu, Space } from 'antd'
import { UserOutlined, LogoutOutlined } from '@ant-design/icons' //AntDesign图标库
import IconSvg from '../../IconSvg' //自编图标库
import { UserWrapper } from '../style'

import * as actionCreators from './userCenter/store/actionCreators'
import UserInfo from './userCenter/userInfo'
import UpdatePassword from './userCenter/updatePassword'
import Contact from './userCenter/contact'
import RegisterPsInfo from './userCenter/registerPsInfo'
import { logout } from '../../../request/api'

const { Meta } = Card;

const UserInfoList = (props) => {
  const { t } = useTranslation();
  const urlRoute = useNavigate();
  const [changeUser, setChangeUser] = useState(0);
  const [userName, setUserName] = useState('');
  const [companyName, setCompanyName] = useState('');

  const beautySub = (str, len) => {
    //length属性读出来的汉字长度为1
    if (str.length * 2 <= len) {
      return str;
    }
    var strlen = 0;
    var s = '';
    for (var i = 0; i < str.length; i++) {
      s = s + str.charAt(i);
      if (str.charCodeAt(i) > 128) {
        strlen = strlen + 2;
        if (strlen >= len) {
          return s.substring(0, s.length - 1) + '...';
        }
      } else {
        strlen = strlen + 1;
        if (strlen >= len) {
          return s.substring(0, s.length - 2) + '...';
        }
      }
    }
    return s;
  }

  useEffect(() => {
    props.handleLoad();
  }, [changeUser])//eslint-disable-line

  //没有绑定过电站的用户，注册电站界面
  useEffect(() => {
    if (props.psList.length === 0) {
      //registerRef.current.handleOpen();
    }
  }, [props.psList])

  useEffect(() => {
    if (!props.isLoad) {
      if (props.userInfo.userName !== null && props.userInfo.userName !== '') {
        let namestr = beautySub(props.userInfo.userName, 24)//props.userInfo.userName.length > 30 ?props.userInfo.userName.substring(0,30) + '...': props.userInfo.userName
        setUserName(namestr)
      }
      if (props.userInfo.companyName !== null && props.userInfo.companyName !== '') {
        let namestr = beautySub(props.userInfo.companyName, 24)//props.userInfo.companyName.length > 30 ?props.userInfo.companyName.substring(0,30) + '...': props.userInfo.companyName
        setCompanyName(namestr)
      }
    }
  }, [props.isLoad])//eslint-disable-line

  const handleChangeUserInfo = (value) => {
    setChangeUser(value)
  }

  const passwordRef = useRef();
  const contactRef = useRef();
  const registerRef = useRef();
  const userInfoRef = useRef();

  //用户退出
  const userLogout = () => {
    var userId = props.userInfo['userId'];
    logout(userId).then(res => {
      if (res.status === 200) {
        cookie.remove('userToken');
        cookie.remove('userId');
        cookie.remove('psId');
        cookie.remove('option');
        cookie.remove('allPs');
        props.loginOut(); //清空store中数据
        window.sessionStorage.removeItem('userToken');
        window.sessionStorage.removeItem('userId');
        window.sessionStorage.removeItem('psId');
        window.sessionStorage.removeItem('option');
        window.sessionStorage.removeItem('allPs');
        urlRoute('/')
      }
    })
  }

  //用户下拉菜单
  const menu = (
    <Menu
      className='userCenter'
      onClick={({ item, key, keyPath, domEvent }) => {
        switch (key) {
          case '1':
            userInfoRef.current.handleOpen();
            break;
          case '2':
            passwordRef.current.handleOpen();
            break;
          case '3':
            contactRef.current.handleOpen();
            break;
          case '5':
            registerRef.current.handleOpen();
            break;
          case '6':
            userLogout();
            break;
          default:
            //openUserCenter(parseInt(key))
            break;
        }
      }}
      items={[
        {
          key: 1,
          label: t('Common.Header.userCenter.userCenter.userInfo'),
          icon: <IconSvg icon='fad fa-user' />
        },
        // {
        //   key: 5,
        //   label: t('Common.Header.userCenter.userCenter.psBind'),
        //   icon: <IconSvg icon='fad fa-house-user' />
        // },
        {
          key: 2,
          label: t('Common.Header.userCenter.userCenter.updatePassword'),
          icon: <IconSvg icon='fad fa-unlock-alt' />
        },
        {
          key: 3,
          label: t('Common.Header.userCenter.userCenter.Contact'),
          icon: <IconSvg icon='fad fa-phone-alt' />
        },
        // {
        //   key: 4,
        //   label: t('Common.Header.userCenter.userCenter.SetStyle'),
        //   icon: <IconSvg icon='fad fa-expand' />
        // },
        {
          type: 'divider',
        },
        {
          key: 6,
          label: t('Common.Header.userCenter.userCenter.quit'),
          icon: <LogoutOutlined />
        },
      ]}
    />
  );

  return (
    <>
      <UserWrapper>
        <Dropdown overlay={menu}>
          <Space>
            <Card bordered={false}>
              <Meta
                avatar={<Avatar size={38} icon={<UserOutlined />} />}
                title={userName}
                description={companyName}
              />
            </Card>
          </Space>
        </Dropdown>

        {/* 修改密码弹出窗 */}
        <UpdatePassword ref={passwordRef} />
        {/* 联系方式弹出窗 */}
        <Contact ref={contactRef} />
        {/* 绑定电站弹出窗 */}
        <RegisterPsInfo ref={registerRef}  />
        {/* 用户信息弹出窗 */}
        <UserInfo ref={userInfoRef} changeUserInfo={handleChangeUserInfo} />

      </UserWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoad: state.getIn(['header', 'userinfoList', 'isLoad']),
    userInfo: state.getIn(['header', 'userinfoList', 'user']),
    psList: state.getIn(['header', 'psinfoList', 'list']),
  }
}
const mapDippathToProps = (dispatch) => {
  return {
    handleLoad() {
      dispatch(actionCreators.userInfoListLoad(true))
      dispatch(actionCreators.getUserInfoList());
    },
    loginOut() {
      dispatch(actionCreators.loginOut());
    }
  }
}

export default connect(mapStateToProps, mapDippathToProps)(UserInfoList);