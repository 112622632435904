import * as constants from './actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  updateDeviceList: [],
  randomNum:0,
})

const ReducreDR = (state = defaultState, action) => {
  switch (action.type) {
    case constants.DEVICEMANAGEMENT_UPDATE_DEVICE:
      return state.set('updateDeviceList', action.data).set("randomNum",Math.floor(Math.random() * 999999))
    default:
      return state;
  }
}
export default ReducreDR