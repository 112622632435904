import * as constants from '../store/actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  isLoad: true,
  searchType: 'month',
  searchMonthDate: null,//选中月日期
  searchYearDate: null,//选中年日期
})

const ReducreDR = (state = defaultState, action) => {
  switch (action.type) {
    case constants.ALARM_CHOOSETYPE_SEARCH_TYPE:
      return state.set('searchType', action.searhcType)
    case constants.ALARM_CHOOSETYPE_MONTH_SEARCH_DATE:
      return state.set('searchMonthDate', action.searchDate)
    case constants.ALARM_CHOOSETYPE_YEAR_SEARCH_DATE:
      return state.set('searchYearDate', action.searchDate)  
    default:
      return state;
  }
}
export default ReducreDR