import * as actions from './actionTypes'

//保存电站设备类型信息
export const deviceTypeList=(data,isLoad)=>({
  type:actions.ALARM_DEVICEANALYSE_DEVICE_TYPES,
  data,
  isLoad
})
//异步加载电站设备类型信息
export const fetchDeviceTypeList=(params)=>({
  type:actions.ALARM_FETCH_DEVICEANALYSE_DEVICE_TYPES,
  params
})

//加载时是否显示加载中信息
export const deviceTypeListLoad=(isLoad)=>({
  type:actions.ALARM_DEVICEANALYSE_DEVICE_TYPES_LOAD,
  isLoad
})

//选中的设备类型信息集合
export const checkDeviceTypeId=(deviceTypeId)=>({
  type:actions.ALARM_DEVICEANALYSE_DEVICE_TYPE_ID,
  deviceTypeId
})
