import styled from 'styled-components'

export const TopBar=styled.div`
    position: fixed;
    top: 0;
    left: 250px;
    right: 0;
    height: 60px;
    background: var(--white-100);
    border-bottom: 0;
    z-index: 100;
    -webkit-box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 0px 6px 0 rgb(206 206 238 / 54%);
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 0px 6px 0 rgb(206 206 238 / 54%);
    transition: var(--transition-normal);
    &.toggled {     
        left: 80px;
   }
    @media (max-width:1024px) {
        &{
            left: 0 !important;          
        }
    }  
`

export const NavBar=styled.nav`
    width: 100%;
    height: 60px;
    padding: 0 24px 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const NavBarRight=styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

//菜单模块
export const TopMenuWrapper=styled.div`
    display: flex;
    align-items: center;
`

//Header中电站查询搜索框
export const SearchMini=styled.div`
    display: none;

    border-right: 1px solid rgb(240 240 240 / 55%);
	margin-right: 0;
    padding-right: 8px;
	cursor: pointer;

    font-size:  var(--fontsize-16);
    transition: var(--transition-normal);

    &:hover {
        color: var(--sky-100);
    }
    
    @media (max-width:1024px) {
        & {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        } 
    }    
`
export const SearchBarWrapper=styled.div`
/* 全局电站搜索框样式 */
.anticon.anticon-search {
    margin-top: 4px !important
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    font-size: var(--fontsize-16);
    line-height: 36px !important;
    letter-spacing: var(--spacing-large) !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	width: var(--wh-568) !important;
	border-radius: 8px !important;
	background-color: var(--white-100);
	border: 1px solid var(--form-border-color);
    box-sizing: border-box;
	padding-left: 2.5rem !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: rgb(134 183 254 / 100%) !important;
	box-shadow: 0 0 0 4px rgb(239 247 255 / 100%) !important;
	background-color: var(--white-100) !important;
}

/* 点击状态下搜索框中的字体颜色变浅 */
.ant-select-focused .ant-select-selection-item {
	color: var(--font-25) !important;
}

.ant-select-dropdown {
	min-width: var(--wh-568) !important;
	width: var(--wh-568) !important;
	padding: 24px !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	color: rgb(24 143 253 / 100%);
	background-color: var(--black-0) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	font-size:  var(--fontsize-16);
	color: var(--white-100) !important;
	font-weight: normal !important;
	background-color: rgb(24 143 253 / 100%) !important;
}

.ant-select-selection-search-input {
    height: 40px !important;
    margin: 0 0 0 30px !important;
}

/* 电站搜索下拉滚动条样式 */
.rc-virtual-list-scrollbar {
	width: 5px !important;
	right: 0 !important;
}

@media (max-width:1220px) {
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		width: 268px !important;
	}
}

@media (max-width:1024px) {
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		width: 268px !important;
	}

	.ant-select-dropdown {
		min-width: none !important;
		width: calc(100% - 44px) !important;
	}
}

.search-show {
    font-size:  var(--fontsize-18);
    left: 15px
}
.search-close {
    font-size: 24px;
    right: -7px;
    cursor: pointer;
    display: none
}

@media (max-width:1024px) {
    & {
        display: none;
    }

    &.full-search-bar {     
        display: flex !important;
        align-items: center;
        width: 100%;
        position: absolute;
        left: 0;
        z-index: 100;
        background: var(--white-100);
        height: 60px;
        padding: 0 1.4rem; 
    }
    .search-close {
        display: block;
    }       
    .search-bar-box {
        width: 100%
    }
    .sidebar-wrapper {
        left: -300px;
        box-shadow: none
    }
}    
`

//用户信息
export const UserWrapper=styled.div`
display: flex;
align-items: center;
height: 60px;
border-left: 1px solid var(--black-5);
margin-left: 1rem;
padding-left: 1rem;
.ant-card-body {
    padding: 0 !important;
}
.ant-card-meta-avatar {
    display: flex;
    align-items: center !important;
}
.ant-card-meta-detail{
    min-width:100px;
}

.ant-card-meta-detail>div:not(:last-child) {
    margin-bottom: 0 !important;
}
.ant-card-meta-title {
    font-size:  var(--fontsize-14);
}
.ant-card-meta-description {
    font-size:  var(--fontsize-12);
}
@media (max-width: 768px) { 
    .ant-card-meta-detail {
        display: none;
    }
    .ant-card-meta-avatar {
        padding-right: 0;
    }
}
`

//移动端 菜单小图标控制
export const MobileToggleMenuWrapper=styled.div`
    width: 0;
    opacity: 0;
    transform: scale(0);
	cursor: pointer;
    @media (max-width:1024px) {
        &{
            width: 25px;
            opacity: 1;
            transform: scale(1);
        }       
    }    
`