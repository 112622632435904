import { takeEvery, put, all } from 'redux-saga/effects'
import { GET_PS_USER_ROLE } from './actionTypes'
import * as actionCreators from './actionCreators'
import { getPsUserRoleByUserId } from '../../../../../request/api'
import cookie from 'react-cookies'


function* saga() {

    yield all([
        yield takeEvery(GET_PS_USER_ROLE, fetchSearchList),

    ])
}
function* fetchSearchList(action) {
    const userId =cookie.load('userId');// window.sessionStorage.getItem("userId");
    try {
        const res = yield getPsUserRoleByUserId(userId,action.data.page,action.data.pageSize);
        if (res.status === 200) {
            const data = res.data;
            const action = actionCreators.setPsUserRole(data)
            yield put(action);
        }
    }
    catch (e) {
        console.log("请求失败");

    }

}


export default saga;



