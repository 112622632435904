import reducre from './reducer'
import psinfoSaga  from '../components/psSearchBox/store/saga'
import userBoxsaga  from '../components/userCenter/store/saga'




import * as globalActions from './actionCreators'
import * as globalConst from './actionTypes'
const sagas=[psinfoSaga,userBoxsaga]
export { reducre,sagas,globalActions,globalConst }