import React, { lazy } from "react";
import { Routes, Route, Navigate } from 'react-router-dom'
// import Home from "./pages/Home";
// import About from "./pages/About";
import { GlobalStyle } from './style';


import './statics/css/icons.css'
import './statics/css/dark-theme.css'
import './statics/css/semi-dark.css'
import 'react-perfect-scrollbar/dist/css/styles.css';
// import 'bootstrap/dist/css/bootstrap.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import './statics/css/app.css'
import { Provider } from 'react-redux'
import Content from "./common/content";
import store from './store';

import './i18n/config'
//import Login from "./pages/Login";
//import Active from "./pages/active";
//import TimeOut from "./pages/timeout";
//import Invalid404 from './pages/invalid404';
//import AutoLogin from "./pages/autoLogin";
const Login = lazy(() => import(/* webpackChunkName: 'login' */'./pages/Login'))
const Active = lazy(() => import(/* webpackChunkName: 'active' */'./pages/Active'))
const TimeOut = lazy(() => import(/* webpackChunkName: 'timeout' */'./pages/TimeOut'))
const AutoLogin = lazy(() => import(/* webpackChunkName: 'autoLogin' */'./pages/autoLogin'))
const Invalid404 = lazy(() => import(/* webpackChunkName: 'invalid404' */'./pages/invalid404'))

function App() {
  return (
    <>
      <Provider store={store}>
        <Routes>
          <Route path="/autoLogin" element={<AutoLogin />} />
          <Route path="/404" element={<Invalid404 />} />
          <Route path='/timeout' element={<TimeOut />} />
          <Route path='/active' element={<Active />} />
          <Route path='/' element={<Login />} />
          <Route path='/login' element={<Login />} />
          <Route path="/*" element={<Content />} />
        </Routes>
      </Provider>
      <GlobalStyle />
    </>
  )
}

export default App;