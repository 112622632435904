import styled from 'styled-components'

import bgimgRegister from '../../../../../statics/svg/transform/SvgRegister.svg';

export const RegisterWrapper=styled.div`
    min-height: 254px;

    padding: 24px;
    background: var(--white-100) url(${bgimgRegister}) no-repeat;
    background-size: 55% auto;
    background-position: calc(100% + 30px)  calc(100% + 30px);
    @media (max-width: 768px) { 
        background-size: 55% auto;
    }
    @media (max-width: 576px) { 
        background: var(--white-100);
        .ModalUserCenter .ant-modal-body,
        .ModalUserCenter .ant-typography {
            text-align: center !important;
        }
    }

    .ant-input-affix-wrapper {
        padding: 8px;
    }
`