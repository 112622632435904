//加载电站设备类型时是否显示加载中...
export const DEVICEANALYSE_DEVICE_TYPES_LOAD='device/type/list/FETCH_DEVICE_TYPES_LOAD';
//异步加载电站设备类型信息
export const FETCH_DEVICEANALYSE_DEVICE_TYPES='device/type/list/FETCH_DEVICE_TYPES';
//处理电站设备类型信息
export const DEVICEANALYSE_DEVICE_TYPES='device/type/list/DEVICE_TYPES';
//选中的设备类型信息数组
export const DEVICEANALYSE_DEVICE_TYPE_CHECKS='device/type/list/DEVICE_TYPE_CHECKS';
//选中查询的日期类型，查询日数据、月数据
export const DEVICEANALYSE_DEVICE_TYPE_SEARCH_TYPE='device/type/list/SEARCH_TYPE';
//选中查询的日期时间
export const DEVICEANALYSE_DEVICE_TYPE_SEARCH_DATE='device/type/list/SEARCH_DATE';
//选中查询生成曲线参数组成类型
export const DEVICEANALYSE_DEVICE_TYPE_CHART_TYPES='device/type/list/CHART_TYPES';
