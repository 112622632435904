import * as constants from './actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  addDeviceList: [],
  randomNum:0
})

const ReducreDR = (state = defaultState, action) => {
  switch (action.type) {
    case constants.DEVICEMANAGEMENT_ADD_DEVICE_LIST:
      return state.set('addDeviceList', action.data).set("randomNum",Math.floor(Math.random() * 999999))
    default:
      return state;
  }
}
export default ReducreDR