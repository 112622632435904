import * as actions from './actionTypes'

export const selectDeviceType=(data)=>({
    type:actions.SELECT_DEVICE_TYPE,
    data

})

export const setDeviceTypeList=(data)=>({
    type:actions.SET_DEVICE_TYPE_LIST,
    data

})

export const getDeviceTypeList=(data)=>({
    type:actions.GET_DEVICE_TYPE_LIST,
    data

})






