import * as actions from './actionTypes'

export const getPsSoip=(psId)=>({
    type:actions.GET_PS_SOIP,
    psId
    
})

export const setPsSoip=(data)=>({
    type:actions.SET_PS_SOIP,
    data
    
})





