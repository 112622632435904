import React, { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next'

import { Button, Result, Modal, Space } from 'antd'

import { SuccessIcon, ErroIcon, WarningIcon, InfoIcon } from '../resulticon' //动态图标
import { sendUserEmail } from '../../request/api'

let languageId = 'zh_CN'

const Success = (props) => {
  const handleClose = () => {
    props.handleModel.destroy();
  }
  return (
    <>
      <Result
        icon={<SuccessIcon />}
        title={props.messageTitle}
        subTitle={props.messageSubTitle}
        extra={[
          <Button shape="round" type="primary" key="login" onClick={handleClose}>
            {props.messageBtn}
          </Button>
        ]}
      />
    </>
  )
}

const Error = (props) => {
  const handleClose = () => {
    props.handleModel.destroy();
  }
  return (
    <>
      <Result
        icon={<ErroIcon />}
        title={props.messageTitle}
        subTitle={props.messageSubTitle}
        extra={[
          <Button shape="round" type="primary" key="back" onClick={handleClose}>
            {props.messageBtn}
          </Button>
        ]}
      />
    </>
  )
}

const Info = (props) => {
  const handleClose = () => {
    props.handleModel.destroy();
  }
  return (
    <>
      <Result
        icon={<InfoIcon />}
        title={props.messageTitle}
        subTitle={props.messageSubTitle}
        extra={[
          <Button shape="round" type="primary" key="close" onClick={handleClose}>
            {props.messageBtn}
          </Button>
        ]}
      />
    </>
  )
}

const Warning = (props) => {
  const { t, i18n } = useTranslation();
  languageId = i18n.language === 'zhCN' ? 'zh_CN' : (i18n.language === 'jaJP' ? 'ja' : 'en');
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  useEffect(() => {
    if (props.isOkBtn)
      setCurrent(1)
    else
      setCurrent(0)
  }, [props.isOkBtn]);

  const handleClose = () => {
    //props.backLogin();
    props.handleModel.destroy();
  }

  //发送激活邮件
  const handleSendEmaill = () => {
    setIsLoading(true)
    props.backLogin();
    sendUserEmail(props.userCode, languageId).then(res => {
      try {
        console.log('res.msg', res.msg)
        props.handleModel.destroy();
        var sucModal = Modal.success();
        sucModal.update({
          icon: '',
          content: (
            <Success
              messageTitle={t('Common.Header.Notification.Warning.Notification.Success.Title')}
              messageSubTitle={t('Common.Header.Notification.Warning.Notification.Success.SubTitle')}
              messageBtn={t('Common.Header.Notification.Warning.Notification.Success.btn')}
              handleModel={sucModal}
            />
          ),
          okButtonProps: { style: { display: 'none' } },// 隐藏确认按钮
          cancelButtonProps: { style: { display: 'none' } }, // 隐藏取消按钮
        });
      } catch (err) {
        var errModal = Modal.error();
        errModal.update({
          icon: '',
          content: (
            <Error
              messageTitle={t('Common.Header.Notification.Warning.Notification.Error.Title')}
              messageSubTitle={t('Common.Header.Notification.Warning.Notification.Error.SubTitle')}
              messageBtn={t('Common.Header.Notification.Warning.Notification.Error.btn')}
              handleModel={errModal}
            />
          ),
          okButtonProps: { style: { display: 'none' } },// 隐藏确认按钮
          cancelButtonProps: { style: { display: 'none' } }, // 隐藏取消按钮
        });
      }
      setIsLoading(false)
    })
  }

  return (
    <>
      <Result
        icon={<WarningIcon />}
        title={props.messageTitle}
        subTitle={props.messageSubTitle}
        extra={[
          <Space>
            <Button shape="round" key="back" onClick={handleClose}>
              {props.messageBtn}
            </Button>
            <Button shape="round" type="primary" loading={isLoading} className={current === 1 ? 'isShow' : 'isHide'} onClick={handleSendEmaill}>
              {t('Common.Header.Notification.Button.label')}
            </Button>
          </Space>
        ]}
      />
    </>
  )
}

export { Success, Error, Info, Warning }





