import * as constants from './actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  checkDeviceTypeIds:[],
  deviceTypeId:null,
  deviceTypes:[],
  isLoad:true,
  searchType:'day',
  searchDate:null,
  searchChartTypes:[],
})


const ReducreDR=(state=defaultState,action)=>{

  switch(action.type){
    case constants.DEVICEANALYSE_DEVICE_TYPES_LOAD:
      return state.set('isLoad',action.isLoad);
    case constants.DEVICEANALYSE_DEVICE_TYPES:
      //if( )
      if(typeof(state.get('checkDeviceTypeIds').length)!='undefined'){
        return state.set('deviceTypes',action.data).set('isLoad',false).set('deviceTypeId',null);
      }
      else{
       
        return state.set('deviceTypes',action.data).set('isLoad',false).set('checkDeviceTypeIds',[action.data[0]['deviceTypeId']]).set('deviceTypeId',action.data[0]['deviceTypeId']);
      }
    
        
      
    // case constants.DEVICEANALYSE_DEVICE_TYPES:
    //   if(action.data.length>0)
    //     return state.set('deviceTypes',action.data).set('isLoad',false).set('checkDeviceTypeIds',[action.data[0]['deviceTypeId']]).set('deviceTypeId',action.data[0]['deviceTypeId']);
    //   else
    //     return state.set('deviceTypes',action.data).set('checkDeviceTypeIds',[]);
    case constants.DEVICEANALYSE_DEVICE_TYPE_CHECKS:
      return state.set('checkDeviceTypeIds',action.deviceTypeIds).set('deviceTypeId',action.deviceTypeId);

    case constants.DEVICEANALYSE_DEVICE_TYPE_SEARCH_TYPE:
      
      return state.set('searchType',action.searhcType)
    case constants.DEVICEANALYSE_DEVICE_TYPE_SEARCH_DATE:
      
      return state.set('searchDate',action.searchDate)
    case constants.DEVICEANALYSE_DEVICE_TYPE_CHART_TYPES:
    
      return state.set('searchChartTypes',action.searchChartTypes)
      
    default:
        return state;    
  }   
}

export default ReducreDR