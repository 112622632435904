import {takeEvery,put, all} from 'redux-saga/effects'
import service from '../../../../../request'
import  * as constants from './actionTypes'
import  * as actionCreators from './actionCreators'



function* saga(){
    
    yield all([
        yield takeEvery(constants.FETCH_DEVICEANALYSE_DEVICE_TYPES, fetchDeviceList)
    ])
}

function* fetchDeviceList(action){
    //getDevicesByPsIdTypeId

    try{
        const res=yield service.get('service-psinfo/psinfo/getDeviceTypeByPsId?psId='+action.params.psId);
        
        if(res.status===200){
            const data=res.data;
            const action = actionCreators.deviceTypeList(data,false)
            yield put(action);
        }

    }
    catch(e){
        console.log("请求失败");
    }
    
}

export default saga;
