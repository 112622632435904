import * as actions from './actionTypes'

//选中查询的日期类型，查询日数据、月数据
export const checkSearchType=(searhcType)=>({
  type:actions.ALARM_CHOOSETYPE_SEARCH_TYPE,
  searhcType
})
//选中具体月份日期
export const checkSearchMonthDate=(searchDate)=>({
  type:actions.ALARM_CHOOSETYPE_MONTH_SEARCH_DATE,
  searchDate
})
//选中具体年份日期
export const checkSearchYearDate=(searchDate)=>({
  type:actions.ALARM_CHOOSETYPE_YEAR_SEARCH_DATE,
  searchDate
})
