import * as actions from './actionTypes'

//保存电站设备类型信息
export const deviceTypeList=(data,isLoad)=>({
    type:actions.DEVICEANALYSE_DEVICE_TYPES,
    data,
    isLoad
})
//异步加载电站设备类型信息
export const fetchDeviceTypeList=(params)=>({
    type:actions.FETCH_DEVICEANALYSE_DEVICE_TYPES,
    params
})
//加载时是否显示加载中信息
export const deviceTypeListLoad=(isLoad)=>({
    type:actions.DEVICEANALYSE_DEVICE_TYPES_LOAD,
    isLoad
})
//选中的设备类型信息集合
export const checkDeviceTypes=(deviceTypeIds,deviceTypeId)=>({
    type:actions.DEVICEANALYSE_DEVICE_TYPE_CHECKS,
    deviceTypeIds,
    deviceTypeId
})

//选中查询的日期类型，查询日数据、月数据
export const checkSearchType=(searhcType)=>({
    type:actions.DEVICEANALYSE_DEVICE_TYPE_SEARCH_TYPE,
    searhcType
})
//选中查询的日期类型，查询日数据、月数据
export const checkSearchDate=(searchDate)=>({
    type:actions.DEVICEANALYSE_DEVICE_TYPE_SEARCH_DATE,
    searchDate
})
//选中查询生成曲线参数组成类型
export const checkChartTypes=(searchChartTypes)=>({
    type:actions.DEVICEANALYSE_DEVICE_TYPE_CHART_TYPES,
    searchChartTypes
})








