import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Space, Typography, Tooltip, Button } from 'antd'
import { WithBracketWrapper, WithRequiredMarkWrapper, WithUnitWrapper, LimitingWidthTextWrapper, HelpCardWrapper, HelpTipWrapper } from './style'

import { QuestionOutlined } from '@ant-design/icons'

/* 括号 */
const WithBracket = (props) => {
  return (
    <>
      <WithBracketWrapper>
        <span>
          {props.carrierContent}
        </span>
      </WithBracketWrapper>
    </>
  )
}

/* 红星号必填项标记 */
const WithRequiredMark = (props) => {
  return (
    <>
      <WithRequiredMarkWrapper>
        <Typography.Text className='required-mark-optional'>
          {props.carrierContent}
        </Typography.Text>
      </WithRequiredMarkWrapper>
    </>
  )
}

/* 单位 */
const WithUnit = (props) => {
  const [isShow, setIsShow] = useState(true);
  useEffect(() => {
    if (props.position === 'suffix' || props.position === undefined) {
      setIsShow(false)
    } else {
      setIsShow(true)
    }
  }, [props.isShow])
  return (
    <>
      <WithUnitWrapper>
        <span className={[isShow ? 'Show' : 'isHide'] + [' '] + [props.type || ''] + [' '] + 'prefixStyle'}>
          <span className={props.unitName}></span>
        </span>
        <span className='carrierContentStyle'>{props.carrierContent}</span>
        <span className={[isShow ? 'isHide' : 'Show'] + [' '] + [props.type || ''] + [' '] + 'suffixStyle'}>
          <span className={props.unitName}></span>
        </span>
      </WithUnitWrapper>
    </>
  )
}

/* 限制宽度的文本 */
const LimitingWidthText = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <LimitingWidthTextWrapper>
        <Typography.Text title={props.text}>
          <span style={{ 'max-width': [props.maxWidth] }}>
            {props.text}
          </span>
        </Typography.Text>
      </LimitingWidthTextWrapper>
    </>
  )
}

/* 帮助文本 */
const HelpCard = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <HelpCardWrapper>
        <Space direction='vertical' size={18}>
          {props.cardContent}
        </Space>
      </HelpCardWrapper>
    </>
  )
}

/* 帮助贴士 */
const HelpTip = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <HelpTipWrapper>
        <Tooltip
          overlayClassName='tooltipHelp'
          placement={props.placement || 'right'}
          title={props.tipContent}
          {...props}
        >
          <Button type='dashed' shape='circle' icon={<QuestionOutlined />} {...props} />
        </Tooltip>
      </HelpTipWrapper>
    </>
  )
}

export { WithBracket, WithRequiredMark, WithUnit, LimitingWidthText, HelpCard, HelpTip }