import * as actions from './actionTypes'

export const setFullSearch=(data)=>({
    type:actions.SET_FULL_SEARCH,
    data
})
export const setLocal=(language)=>({
    type:actions.SET_LOCALE,
    language
})

export const setMobileMenu=(isFlag)=>({
    type:actions.SET_MOBILE_MENU,
    isFlag
})

