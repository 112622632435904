import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    /* 字体设置 */
    /* body {
        font-family: Arial, 'Times New Roman', 'Microsoft YaHei', "SimHei";
    } */
    /* [lang="en"] body {
        font-family: "Hiragino Sans GB", "Helvetica Neue", Helvetica, Arial, sans-serif;       
    }
    [lang^="zh"] body {
        font-family: "Hiragino Sans GB", "华文细黑", "STHeiti", " 微软雅黑", "Microsoft YaHei", "SimHei", "Helvetica Neue", Helvetica, Arial, sans-serif;        
    }
    [lang="ja"] body {
        font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", "MS Gothic", "ＭＳ ゴシック", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    [lang="ko"] body {
        font-family: "나눔 고딕", "Nanum Gothic", "맑은 고딕", "Malgun Gothic", " Apple Gothic", " 돋움", "Dotum", "Helvetica Neue", Helvetica, Arial, sans-serif;
    } */

    /* 英文文本排版调试 */
    /* @media (min-width: 1601px) and (max-width: 1980px) {
        [lang="en"] .ant-spin-container {
            letter-spacing: 0px;
        }
    } */

    [lang="ja"] body {
        font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif !important;
    }

    [lang="en"] body {
        font-family: -apple-system, BlinkMacSystemFont, Arial, 'Times New Roman', 'Microsoft YaHei', "SimHei" !important;
    }

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    /* table, caption, tbody, tfoot, thead, tr, th, td, */
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, s
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1 !important;
        font-size:  var(--fontsize-14) !important;
        color: var(--font-85) !important;
        letter-spacing: var(--spacing-normal) !important;
        background: var(--background-keynote) !important;
        overflow-x: hidden !important;
        font-family: Arial, sans-serif !important;
    }
    ol, ul {
        list-style: none;
    }
    /* .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        color: #32393f;
    } */
    /* .h6, h6 {
        font-size: 1rem;
    } */
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    a {
        text-decoration: none;
    }
    .ant-space-vertical {
      width: 100% !important;
    }

    .ant-checkbox-group {
      width: 100% !important;
    }
`