import * as actions from './actionTypes'
//点击日历日期
export const checkClickDate=(clickDate)=>({
  type:actions.ALARM_CHOOSETYPE_CLICK_DATE,
  clickDate
})

export const setIsOpen=(isOpen)=>({
  type:actions.ALARM_ISOPEN,
  isOpen
})
