import * as constants from './actionTypes';
import { fromJS } from 'immutable';

const defaultState = fromJS({
    visiblePs: [],
    visibleType: "",
    psId:''
});

const ReducreFn = (state = defaultState, action) => {
    switch (action.type) {
        case constants.SET_VISIBLE_PS:
            return state.set('visiblePs', action.data);
        case constants.SET_VISIBLE_TYPE:
            return state.set('visibleType', action.data);
        default:
            return state;

    }
}

export default ReducreFn