import * as actions from './actionTypes'

export const setMenuList=(data)=>({
  type:actions.SET_MENU_LIST,
  data,
})

export const getMenuList=()=>({
  type:actions.GET_MENU_LIST
})


export const selectedMenu=(selectedKeys)=>({
  type:actions.SET_MENU_ID,
  selectedKeys
})

export const setMenuCollapsed=()=>({
  type:actions.SET_MENU_COLLAPSED,
})

export const setMobileMenu=(isFlag)=>({
  type:actions.SET_MOBILE_MENU,
  isFlag
})
