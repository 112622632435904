import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next' //语言包
import { connect } from 'react-redux'

import { Space, Form, Input, Divider, Typography, Modal } from 'antd'
import { InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { RegisterWrapper } from './style'

import { valiPmuSn } from '../../../../../utils/validator'
import { addUserPsRole } from '../../../../../request/api'

const RegisterPsInfo = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    handleOpen
  }))

  const [isOpen, setIsOpen] = useState(false)

  const handleCancel = () => {
    setIsOpen(false);
    registerPsForm.resetFields();
    setIsError(200)
    //props.onCancelRes()
  };

  const handleOpen = () =>{
    setIsOpen(true);
  }

  const { t } = useTranslation();

  const [registerPsForm] = Form.useForm();
  const [isError, setIsError] = useState(200);
  const [isLoading, setIsLoading] = useState(false);

  const handleOk = () => {
    registerPsForm.validateFields().then(values => {
      setIsLoading(true)
      var params={pmuSn: '',userId:''}
      params['pmuSn']=values.registerPsForm['pmuSn'];
      params['userId'] = props.userInfo['userId']
      addUserPsRole(params).then((res) => {
        setIsError(res.status)
        if (res.status === 200) {
          //绑定成功刷新页面
          window.location.reload() // 强制页面刷新
        } else  {
          //显示错误信息        
        }
        setIsLoading(false)
      })
    }).catch(err => {
  
      console.info('login err', err)
    })  
  };

  return (
    <>
      <Modal
        className='ModalSmall ModalUserCenter DividerCenterSM'
        title={t('Common.Header.userCenter.userCenter.psBind')}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t('All.Operation.submit')}
        cancelText={t('InterfaceMsg.502.btn')}      
        okButtonProps={{ loading: isLoading }}
        cancelButtonProps={{ style: { display: 'none' } }}
        width= {800}
      >
        <RegisterWrapper>
          <Form
            layout='vertical'
            hideRequiredMark
            form={registerPsForm}
            onFinish={handleOk}
          >
            <Divider className='DividerWarning' orientation='left' orientationMargin='0'>
              <Typography.Text>{t('Common.Header.RegisterPs.pmuSn')}</Typography.Text>
              <Divider type='vertical' />
              <Typography.Text type='secondary' className={isError===200? 'fadeShow' : 'fadeHide'}>
                <Space size={0}>
                  <InfoCircleOutlined />
                  {t('Common.Header.RegisterPs.bindTips')}
                </Space>
              </Typography.Text>
              <Typography.Text type='danger' className={isError===646? 'fadeShow' : 'fadeHide'}>
                <Space size={0}>
                  <CloseCircleOutlined />
                  {t('Common.Header.RegisterPs.646')}
                </Space>
              </Typography.Text>
              <Typography.Text type='danger' className={isError===626? 'fadeShow' : 'fadeHide'}>
                <Space size={0}>
                  <CloseCircleOutlined />
                  {t('Common.Header.RegisterPs.626')}
                </Space>
              </Typography.Text>
            </Divider>

            <Form.Item
              // label={t('Common.Header.RegisterPs.pmuSn')}
              name={['registerPsForm', 'pmuSn']}
              validateFirst={true}
              validateTrigger={['onBlur','onSubmit']}
              rules={[
                { required: true, message: t('All.Result.Error.FormRules.requiredItem') },
                { validator: valiPmuSn, message:t('Common.Header.RegisterPs.range') },
              ]}
            >
              <Input 
                placeholder={t('Common.Header.RegisterPs.pleasePmuSn')}
                autocomplete='off'
                maxLength={15}
                showCount
              />
            </Form.Item>
          </Form>

        </RegisterWrapper>

      </Modal>
    </>
  )
})

const mapStateToProps = (state) => {
  return {
    psList: state.getIn(['header', 'psinfoList', 'list']),
    userInfo: state.getIn(['header', 'userinfoList', 'user'])
  }
}
const mapDippathToProps = (dispatch) => {
  return {
    handleLoad () {},
  }
}

export default connect(mapStateToProps, mapDippathToProps,null, { forwardRef: true })(RegisterPsInfo);