import styled from 'styled-components'

export const ContactWrapper=styled.div`
.ant-divider .ant-typography {
    font-size:  var(--fontsize-16) !important;
  }

  .ant-divider.DividerSecondary .ant-typography {
    font-size:  var(--fontsize-14) !important;
    font-weight: normal !important;
  }

  .ant-divider-horizontal.ant-divider-with-text::before,
  .ant-divider-horizontal.ant-divider-with-text::after {
    border-bottom: 1px solid rgb(0 0 0 / 5.85%) !important;
    border-top: 1px solid transparent !important;
  }

  .ant-divider-horizontal.ant-divider-with-text.DividerSecondary::before,
  .ant-divider-horizontal.ant-divider-with-text.DividerSecondary::after {
    border-bottom: 1px solid rgb(0 0 0 / 1.85%) !important;
    border-top: 1px solid transparent !important;
  }
`