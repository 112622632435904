import React,{useState, forwardRef, useImperativeHandle} from 'react'
import { useTranslation } from 'react-i18next' //语言包
import { connect } from 'react-redux'

import { Modal } from 'antd'
import { ContactWrapper } from './style'

import { ContactInfo } from '../../../../contactInfo'

const Contact = forwardRef((props, ref) => {
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    handleOpen   
  }))

  const [isOpen, setIsOpen] = useState(false)

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleOpen = () =>{
    setIsOpen(true);
  }

  return (
    <>
      <Modal
        className='ModalSmall ModalUserCenter DividerCenterSM'
        title={t('Common.Header.userCenter.userCenter.Contact')}
        open={isOpen}
        onCancel={handleCancel}
        okText={t('All.Operation.submit')}
        cancelText={t('InterfaceMsg.502.btn')}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <ContactWrapper>
          <ContactInfo />
        </ContactWrapper>
      </Modal>
    </>
  )
})

export default connect(null, null, null, { forwardRef: true })(Contact)