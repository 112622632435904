import * as constants from './actionTypes';
import { fromJS } from 'immutable';

const defaultState=fromJS({
  flag:null
});

const ReducreFn=(state=defaultState,action)=>{
  switch (action.type) {
    case constants.GO:
      var flagT=state.get("flag")===null?true:!state.get("flag")
      return state.set('flag',flagT )
    default:
      return state;
  }

}

export default ReducreFn