import * as constants from './actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  searchType:null,
  searchDate:null
})


const ReducreDR=(state=defaultState,action)=>{

  switch(action.type){
    case constants.DEVICEREPORT_SEARCH_DATE:
      return state.set('searchType',action.searchDate);
    case constants.DEVICEREPORT_SEARCH_INIT:
      return state.set('searchType',action.searchType).set('searchDate',action.searchDate);
      
    case constants.DEVICEREPORT_SEARCH_TYPE:
      return state.set('searchDate',action.searchDate);
    default:
        return state;    
    
}   
}

export default ReducreDR