import * as actions from './actionTypes'
import { fromJS } from 'immutable';
const defaultState = fromJS({
  defaultValue:'21511288YT14KP98',//选中值
  selectedKeys:'',
  list:[],
  collapsed:false,//左右是否展开(手机端)
})

//拼接数据格式
const resultMenu=(data)=>{
  var list=[]
  var parentId; 
  data.forEach(function(i,val) {
      var listValue={};
      if(i.parentId === ''){
        listValue["menuId"]=i.menuId;
        listValue["menuName"]=i.menuName;
        listValue["parentId"]=i.parentId;
        listValue["url"]=i.url;
        listValue["children"]=[];
        parentId = i.menuId
      }
      for(let j = 0;j < data.length;j++){
        if(listValue["children"] !== undefined &&  parentId === data[j].parentId && data[j].parentId !== ''){
           var child = {}
           child["menuId"] = data[j].menuId;
           child["menuName"]=data[j].menuName;
           child["parentId"]=data[j].parentId;
           child["url"]=data[j].url;
           listValue["children"].push(child);
        }
     }
     if(i.parentId === ''){
        list.push(listValue);
     }
  });
  return list;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState,action) =>{
    switch(action.type){
      case actions.SET_MENU_LIST:
          //console.info("menu/reducer/newState.list",resultMenu(action.data));
          return state.set('list',resultMenu(action.data));
      case actions.SET_MENU_ID:
          //console.log("menu/reducer/defaultValue",action.selectedKeys);
          return state.set('selectedKeys',action.selectedKeys);
      case actions.SET_MENU_COLLAPSED:
          //console.log("menu/reducer/collapsed",!state.collapsed);
          const newState = JSON.parse(JSON.stringify(state));
          newState.collapsed = !newState.collapsed;
          return state.set('collapsed', newState.collapsed);
      case actions.SET_MOBILE_MENU:
          return state.set('collapsed', action.isFlag);  
      default:
          return state;
    }
}