import * as actions from './actionTypes'

//选中查询的日期类型，查询日数据、月数据
export const checkMaskSearchType=(searhcType)=>({
  type:actions.ALARM_MASKCHOOSETYPE_SEARCH_TYPE,
  searhcType
})

//选中具体年份日期
export const checkMaskSearchYearDate=(searchDate)=>({
  type:actions.ALARM_MASKCHOOSETYPE_YEAR_SEARCH_DATE,
  searchDate
})
