import {takeEvery,put, all} from 'redux-saga/effects'
import service from '../../../../../request'
import  * as constants from './actionTypes'
import  * as actionCreators from './actionCreators'



function* saga(){
    
    yield all([
        yield takeEvery(constants.FETCH_DEVICEREPORT_DEVICE_LIST, fetchDeviceList)
    ])
}

function* fetchDeviceList(action){
    //getDevicesByPsIdTypeId

    try{
        const res=yield service.get('service-psinfo/psinfo/getDevicesByPsIdTypeId?psId='+action.params.psId);
        var deviceId= action.params.deviceId;
        var deviceType=action.params.deviceType;
        if(res.status===200){
            const data=res.data;
            const action = actionCreators.deviceList(data,false,deviceId,deviceType)
            yield put(action);
        }

    }
    catch(e){
        console.log("请求失败");
        
    }
    
}

export default saga;
