import { combineReducers } from 'redux-immutable';
import {reducre as deviceListReducre} from '../components/deviceList/store'
import {reducre as datePickerList} from '../components/datePickerList/store'
const defaultCombine = combineReducers({

  deviceList:deviceListReducre,
  datePicker:datePickerList
})


export default defaultCombine


