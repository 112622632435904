import { combineReducers } from 'redux-immutable';
import {reducre as addDeviceInfoReducer} from '../components/addDeviceInfo/store'
import {reducre as updateDeviceInfoReducer} from '../components/editDeviceInfo/store'
import {reducre as deviceListReducer} from '../components/deviceList/store'

const defaultCombine = combineReducers({
  addDeviceInfo:addDeviceInfoReducer,
  updateDeviceInfo:updateDeviceInfoReducer,
  deviceList:deviceListReducer
})
export default defaultCombine