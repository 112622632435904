import {takeEvery,put, all} from 'redux-saga/effects'
import axios from 'axios'
import  * as constants from './actionTypes'
import * as actionCreators from './actionCreators'


function* saga(){

    yield all([
        yield takeEvery(constants.FETCH_HOME_LIST, fetchSearchList)
    ])
}

function* fetchSearchList(data){
    try{
        const res=yield axios.get('apiList.json');
        if(res.data.success){
            yield put(actionCreators.searchList(res.data.data));
        }
        
    }
    catch(e){
        console.log("请求失败");
    }
    
}

export default saga;



