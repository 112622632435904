import * as actions from './actionTypes'

//加载时是否显示加载中信息
export const userInfoListLoad = (isLoad) => ({
    type: actions.GET_USERINFOR_LOAD,
    isLoad
})

export const setUserInfoList = (data) => ({
    type: actions.SET_USERINFOR_LIST,
    data
})

export const getUserInfoList = () => ({
    type: actions.GET_USERINFOR_LIST
})


export const loginOut = () => ({
    type: actions.RESET_DATA
})



