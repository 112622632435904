import * as actions from './actionTypes'

export const setCountryList=(data)=>({
  type:actions.SET_COUNTRY_LIST,
  data,
})

export const getCountryList=()=>({
  type:actions.GET_COUNTRY_LIST,
})

export const logingNetworkError=()=>({
  type:actions.LOGIN_NETWORK_ERROR,
})