import * as constants from './actionTypes';
import { fromJS } from 'immutable';

const defaultState=fromJS({
  defaultValue:'',
});

const ReducreFn=(state=defaultState,action)=>{

    switch(action.type){
        case constants.SELECT_DEVICE_TYPE:
            return state.set('defaultValue',action.data);
        default:
            return state;    
        
    }
}

export default ReducreFn