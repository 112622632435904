import React,{useEffect,useState} from 'react'
import { MobileToggleMenuWrapper } from '../style'
import * as actionCreators from '../store/actionCreators'
import {connect} from 'react-redux'

import IconSvg from '../../IconSvg'

const MobileToggleMenu=(props)=>{

  const [winWidth,setWinWidth] = useState(0)
  const resizeUpdate = () =>{
      let w = document.body.clientWidth;
      setWinWidth(w)
  }
   useEffect(()=>{

    let w = window.innerWidth;
    setWinWidth(w)
    //console.log("windowWidth",winWidth);
    window.addEventListener('resize',resizeUpdate);
    if(winWidth <= 1024){
       if(props.collapsed && !props.isMobileMenuFlag){
          props.handleMobileMenu(true)
       }
    }else{
      props.handleMobileMenu(false)
    }
    
    if(!props.collapsed){
      props.handleMobileMenu(props.collapsed)
    }
    
    return() =>{
      window.removeEventListener('resize',resizeUpdate);
    }
   },[props.collapsed,winWidth]);//eslint-disable-line

   const mobileToggle =()=>{
      if(!props.isMobileMenuFlag){
        props.handleMobileMenu(true)
      }
   }

   return(
				<MobileToggleMenuWrapper onClick={mobileToggle}>
          <IconSvg icon="fad fa-bars" size='normal' />
				</MobileToggleMenuWrapper>
   )
}

const mapStateToProps=(state)=>{
   return{
      collapsed:state.getIn(['menu','collapsed']),
      isMobileMenuFlag:state.getIn(['header','global','isMobileMenuFlag']),
   }
 } 
 
 const mapDispathToProps=(dispatch)=>{
   return{
     handleMobileMenu(isFlag){
       dispatch(actionCreators.setMobileMenu(isFlag)) 
     }
   }
 } 
 
 export default connect(mapStateToProps,mapDispathToProps)(MobileToggleMenu);