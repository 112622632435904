import styled from 'styled-components'

export const MiniNoteWrapper=styled.div`
  .ant-typography.ant-typography-warning {
    color: #f8bb86 !important;
  }

  .ant-typography {
    position: relative;
    z-index: 9;
  }

  .sweet .sa-icon {
    margin: 0 auto 10px auto;
  }
`