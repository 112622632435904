import styled from 'styled-components'

import bgimgContact from '../../../../../statics/svg/transform/SvgContact.svg';

export const ContactWrapper=styled.div`
    min-height: 320px;
    
    padding: 24px;
    background: var(--white-100) url(${bgimgContact}) no-repeat;
    background-size: 45% auto;
    background-position: calc(100% + 30px)  calc(100% + 30px);
    @media (max-width: 768px) { 
        background-size: 43% auto;
    }
    @media (max-width: 576px) { 
        background: var(--white-100);
        text-align: center;
    }
`