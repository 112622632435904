import React from "react"
import { Typography } from 'antd'
import { SuccessIcon, ErroIcon, WarningIcon, InfoIcon } from '../resulticon' //动态图标

import { MiniNoteWrapper } from './style'

const MiniSuccess = (props) => (
  <>
    <MiniNoteWrapper>
      <SuccessIcon />
      <Typography.Text type="success">{props.text}</Typography.Text>
    </MiniNoteWrapper>
  </>
)

const MiniError = (props) => (
  <>
    <MiniNoteWrapper>
      <ErroIcon />
      <Typography.Text type="danger">{props.text}</Typography.Text>
    </MiniNoteWrapper>
  </>
)

const MiniInfo = (props) => (
  <>
    <MiniNoteWrapper>
      <InfoIcon />
      <Typography.Text type="default">{props.text}</Typography.Text>
    </MiniNoteWrapper>
  </>
)

const MiniWarning = (props) => (
  <>
    <MiniNoteWrapper>
      <WarningIcon />
      <Typography.Text type="warning">{props.text}</Typography.Text>
    </MiniNoteWrapper>
  </>
)

export { MiniSuccess, MiniError, MiniInfo, MiniWarning }