import {takeEvery,put, all} from 'redux-saga/effects'
import service from '../../../../../request'
import  * as constants from './actionTypes'
import  * as actionCreators from './actionCreators'

function* saga(){
  yield all([
      yield takeEvery(constants.FETCH_FAILURETYPE_TYPES_LIST, fetchFailureList)
  ])
}

function* fetchFailureList(){
  try{
      const res=yield service.get('service-base/failureType/getFailureTypes');
      
      if(res.status===200){
          const data=res.data;
          console.log(data)
          const action = actionCreators.failureTypeList(data,false)
          yield put(action);
      }

  }
  catch(e){
      console.log("请求失败");
  }
  
}

export default saga;