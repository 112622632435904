import React,{useState} from 'react'
import { useTranslation } from 'react-i18next' //语言包
import { connect } from 'react-redux'
import Joyride from 'react-joyride'

import { Space, Typography } from 'antd'
import { TourWrapper } from './style'

import { LinkTextOperation } from '../operation'

interface IProps {
  run: boolean;
  onSetStatus: any;
}

const Tour = (props: IProps) => {
  const { t } = useTranslation();
  const { run = true, onSetStatus } = props;

  const [steps]= useState([{
    target: '.addPlant',
    content: 
      <>
        <Space direction='vertical'>
          <Typography.Text>
            {t('All.Copywriting.TourTip.clickCanAddPlant')}
          </Typography.Text>
          <LinkTextOperation
            onClick={ ()=>{props.handleBack('skip');onSetStatus(false);} }
            linkText={t('All.Operation.Add.addPlantNow')}
          />
        </Space>
      </>
    ,
    disableBeacon: true,
    hideCloseButton: true,
  },
  {
    target: '.deviceManagement',
    content:
       <>
        <Space direction='vertical'>
          <Typography.Text>
            {t('All.Copywriting.TourTip.clickCanAddDevice')}
          </Typography.Text>
        </Space>
      </>,
  }]);

  const handleJoyrideCallback = (data) => {
    const { action } = data;
    switch (action) {
      case 'stop':
         props.handleBack('skip')
        break;
      case 'back':
        break;
      case 'close':
        break;
      case 'last':
        props.handleBack('skip') 
        break;
      case 'next':
        props.handleBack(action)
        break;
      case 'skip':
        props.handleBack(action)
      case 'reset':
        onSetStatus(false);
        break;
      default:
        break;
    }
  };
  
  return (
    <>
    {/* { JSON.stringify(props.steps)+''} */}
      <TourWrapper>
        <Joyride
          steps={steps}
          continuous={true}
          locale={{ back: t('All.Operation.back'), close: null, last: t('All.Operation.ok'),  next: t('All.Operation.next') }}
          hideCloseButton={true}
          showProgress={false}
          showSkipButton={false}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          run={run}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              zIndex: 1000,
            },
          }}
        />
      </TourWrapper>
    </>
  )
}

export default connect()(Tour);