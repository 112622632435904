import { takeEvery, put, all } from 'redux-saga/effects'
import { GET_VISIBLE_PS } from './actionTypes'
import * as actionCreators from './actionCreators'
import { getPsVisible } from '../../../../../request/api'



function* saga() {

    yield all([
        yield takeEvery(GET_VISIBLE_PS, fetchSearchList),

    ])
}
function* fetchSearchList(action) {
    try {
        const res = yield getPsVisible(action.data.psId,action.data.psTypeId);
        if (res.status === 200) {
            const data = res.data;
            const action = actionCreators.setVisiblePs(data)
            yield put(action);
        }
    }
    catch (e) {
        console.log("请求失败");

    }

}


export default saga;


