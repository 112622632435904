import * as actions from './actionTypes'

//加载时是否显示加载中信息
export const getPsUserRole=(data)=>({
    type:actions.GET_PS_USER_ROLE,
    data
    
})

export const setPsUserRole =(data)=>({
    type:actions.SET_PS_USER_ROLE,
    data
    
})

export const setSelPs =(data)=>({
    type:actions.SET_SEL_PS,
    data
    
})







