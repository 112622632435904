import * as actions from './actionTypes'

//加载时是否显示加载中信息
export const update=(isUpdate)=>({
    type:actions.PSINFO_UPDATE,
    isUpdate
})

export const setPsinfo=(data)=>({
  type:actions.SET_PSINFO,
  data
})

export const getPsinfo=(psId)=>({
  type:actions.GET_PSINFO,
  psId
})