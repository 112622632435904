import * as constants from '../store/actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  isLoad: true,
  searchMaskType: '1',
  searchMaskYearDate: null,//选中年日期
})

const ReducreDR = (state = defaultState, action) => {
  switch (action.type) {
    case constants.ALARM_MASKCHOOSETYPE_SEARCH_TYPE:
      return state.set('searchMaskType', action.searhcType)
    case constants.ALARM_MASKCHOOSETYPE_YEAR_SEARCH_DATE:
      return state.set('searchMaskYearDate', action.searchDate)  
    default:
      return state;
  }
}
export default ReducreDR