import * as actions from './actionTypes'

//加载时是否显示加载中信息
export const psInfoListLoad=(isLoad)=>({
    type:actions.PSINFO_LIST_LOAD,
    isLoad
  })

export const setPsInfoList=(data)=>({
    type:actions.SET_PSINFOR_LIST,
    data
})

export const getPsInfoList=()=>({
    type:actions.GET_PSINFOR_LIST

})

export const selectPs=(data)=>({
    type:actions.SELECT_PS,
    data

})

export const setPsinfo=(data)=>({
    type:actions.SET_PSINFO,
    data

})

export const setAllPs=(allPs)=>({
    type:actions.SET_ALLPS,
    allPs
})

