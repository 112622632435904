import {takeEvery,put, all} from 'redux-saga/effects'
import service from '../../../../../request/index'
import  * as constants from './actionTypes'
import  * as actionCreators from './actionCreators'

function* saga(){
    
  yield all([
      yield takeEvery(constants.ALARM_FETCH_DEVICEANALYSE_DEVICE_TYPES, fetchDeviceList)
  ])
}

function* fetchDeviceList(action){
  try{
       var url = ''
       if(action.params.psId !==''){
         url = 'service-psinfo/psinfo/getDeviceTypeByPsId?psId='+action.params.psId
       }else{
         url = 'service-base/deviceType/getDeviceTypes'
       }
     
       const res=yield service.get(url);
      if(res.status===200){
          const data=res.data;
          console.log(data)
          const action = actionCreators.deviceTypeList(data,false)
          yield put(action);
      }

  }
  catch(e){
      console.log("请求失败");
  }
  
}

export default saga;