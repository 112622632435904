import * as actions from './actionTypes'

//点击日历日期
export const checkClickDate=(clickDate)=>({
  type:actions.ALARM_CHOOSETYPE_CLICK_YEAR_DATE,
  clickDate
})


//点击日历日期
export const checkFailureTypeId=(failureTypeId)=>({
  type:actions.ALARM_CHOOSETYPE_FAILURE_TYPE_ID,
  failureTypeId
})