import styled from 'styled-components'

import bgimgPsInfo from '../../../../../statics/svg/transform/SvgPsInfo.svg';

export const UserInfoWrapper=styled.div`
    min-height: 254px;
    padding: 24px;
    
    background: var(--white-100) url(${bgimgPsInfo}) no-repeat;
    background-size: 40% auto;
    background-position: calc(100% + 75px)  calc(100% + 35px);
    @media (max-width: 768px) { 
        background-size: 50% auto;
    }
    @media (max-width: 576px) { 
        background: var(--white-100);
    }
`