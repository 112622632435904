import styled from 'styled-components'

import bgimgPassWord from '../../../../../statics/svg/transform/SvgPassWord.svg';

export const UpdatePasswordWrapper=styled.div`
    min-height: 254px;
    
    padding: 24px;
    background: var(--white-100) url(${bgimgPassWord}) no-repeat;
    background-size: 42% auto;
    background-position: calc(100% + 8px)  calc(100% + 31px);
    @media (max-width: 768px) { 
        background-size: 40% auto;
    }
    @media (max-width: 576px) { 
        background: var(--white-100);
        .ModalUserCenter .ant-modal-body,
        .ModalUserCenter .ant-typography {
            text-align: center !important;
        }
        .ant-form-item {
            display: flex;
            justify-content: center !important;
        }
    }

    .ant-form-item-control,
    .ant-input-affix-wrapper {
        width: 100% !important;
        min-width: 305px;
        max-width: 305px;
    }

    .ant-input-affix-wrapper {
        padding: 8px;
    }
`