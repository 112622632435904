import React from 'react'
import { useTranslation } from 'react-i18next' //语言包

import { Typography, Divider, Space } from 'antd'
import { ContactWrapper } from './style'

const ContactInfo = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContactWrapper>
        <Space direction='vertical' size='large'>
          <Space direction='vertical' size={0}>
            <Divider>
              <Typography.Text strong>{t('ContactUs.EmailTitle')}</Typography.Text>
            </Divider>

            <Divider className='DividerSecondary'>
              <Typography.Text>{t('ContactUs.EmailSonTitle1')}</Typography.Text>
            </Divider>
            <Typography.Text className='number-style-large'>sales@taoke-energy.com</Typography.Text>

            <Divider className='DividerSecondary'>
              <Typography.Text>{t('ContactUs.EmailSonTitle2')}</Typography.Text>
            </Divider>
            <Typography.Text className='number-style-large'>support@taoke-energy.com</Typography.Text>
          </Space>
            
          <Space direction='vertical' size={0}>
            <Divider>
              <Typography.Text strong>{t('ContactUs.TelTitle')}</Typography.Text>
            </Divider>
            <Typography.Text className='number-style-larger'>03-6452-8666</Typography.Text>
            <Typography.Text>{t('ContactUs.TelDate')}</Typography.Text>
            <Typography.Text>{t('ContactUs.TelDate1')}</Typography.Text>
            <Typography.Text>{t('ContactUs.TelDate2')}</Typography.Text>
            <Typography.Text>{t('ContactUs.TelDate3')}</Typography.Text>
          </Space>

        </Space>
      </ContactWrapper>
    </>
  )
}

export { ContactInfo }
