import React from "react";

import './style.css';

const SuccessIcon = () => {
  return (
    <>
      <div class="sweet">
        <div class="sa-icon sa-success animate">
          <span class="sa-line sa-tip animateSuccessTip"></span>
          <span class="sa-line sa-long animateSuccessLong"></span>
          <div class="sa-placeholder"></div>
          <div class="sa-fix"></div>
        </div>
      </div>
    </>
  )
}

const ErroIcon = () => {
  return (
    <>
      <div class="sweet">
        <div class="sa-icon sa-error animateErrorIcon">
          <span class="sa-x-mark animateXMark">
            <span class="sa-line sa-left"></span>
            <span class="sa-line sa-right"></span>
          </span>
        </div>
      </div>
    </>
  )
}

const WarningIcon = () => {
  return (
    <>
      <div class="sweet">
        <div class="sa-icon sa-warning pulseWarning">
          <span class="sa-body pulseWarningIns"></span>
          <span class="sa-dot pulseWarningIns"></span>
        </div>
      </div>
    </>
  )
}

const InfoIcon = () => {
  return (
    <>
      <div class="sweet">
        <div class="sa-icon sa-info"></div>
      </div>
    </>
  )
}

export { SuccessIcon, ErroIcon, WarningIcon, InfoIcon }