import * as actions from './actionTypes'

export const deviceList=(data,isLoad,deviceId,deviceType,deviceModule)=>({
    type:actions.DEVICEREPORT_DEVICE_LIST,
    data,
    isLoad,
    deviceId,
    deviceType,
    deviceModule
})
export const fetchDeviceList=(params)=>({
    type:actions.FETCH_DEVICEREPORT_DEVICE_LIST,
    params
})

export const deviceListLoad=(isLoad)=>({
    type:actions.DEVICEREPORT_DEVICE_LIST_LOAD,
    isLoad
})

export const checkDevice=(params)=>({
    type:actions.DEVICEREPORT_DEVICE_CHECK,
    params
})


