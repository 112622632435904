import {takeEvery,put, all} from 'redux-saga/effects'
import axios from 'axios'
import  { GET_MENU_LIST } from './actionTypes'
import * as actionCreators from './actionCreators'

function* saga(){
  yield all([
     yield takeEvery(GET_MENU_LIST, fetchMenuList),
  ])

  function* fetchMenuList(){
    try{
        const res=yield axios.get('api1/getMenusByRoleId.json?v=1.0');
        if(res.status===200){
            const data=res.data.data;
            const action = actionCreators.setMenuList(data)
            yield put(action);
        }
        
    }
    catch(e){
        console.log("请求失败"); 
    }
  }
}

export default saga;