import * as constants from '../store/actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  isLoad: true,
  failureTypeId: null,
  failureTypes: [],
})

const ReducreDR = (state = defaultState, action) => {
  switch (action.type) {
    case constants.FAILURETYPE_TYPES_LIST_LOAD:
      return state.set('isLoad', action.isLoad);
    case constants.FAILURETYPE_TYPES_LIST:
      if (action.data.length > 0) {
        return state.set('failureTypes', action.data).set('isLoad', false)//set('failureTypeId', action.data[0].failureTypeId);
      } else {
        return state.set('failureTypes', [])
      }
    case constants.FAILURETYPE_TYPES_ID:
      return state.set('failureTypeId', action.failureTypeId)
    default:
      return state;
  }
}
export default ReducreDR