import * as constants from '../store/actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  clickDate: null,
  isOpen:false
})

const ReducreDR = (state = defaultState, action) => {
  switch (action.type) {
    case constants.ALARM_CHOOSETYPE_CLICK_DATE:
      return state.set('clickDate', action.clickDate)
    case constants.ALARM_ISOPEN:
      return state.set('isOpen', action.isOpen)
    default:
      return state;
  }
}
export default ReducreDR