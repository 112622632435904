import { takeEvery, put, all } from 'redux-saga/effects'
import { GET_PS_SOIP } from './actionTypes'
import * as actionCreators from './actionCreators'
import { getPsSoipDatas } from '../../../../../request/api'


function* saga() {

    yield all([
        yield takeEvery(GET_PS_SOIP, fetchSearchList),

    ])
}
function* fetchSearchList(action) {
    try {
        const res = yield getPsSoipDatas(action.psId);
        if (res.status === 200) {
            const data = res.data;
            const action = actionCreators.setPsSoip(data)
            yield put(action);
        }
    }
    catch (e) {
        console.log("请求失败");

    }

}


export default saga;



