import { combineReducers } from 'redux-immutable'
// import {reducre as psinfoListReducer} from '../common/header/components/psinfoList/store'
import {reducre as headerReducer} from '../common/header/store'
import {reducre as menuReducer} from '../common/menu/store'
import {reducre as homeReducer} from '../pages/Home/store'

import {reducre as deviceReportReducer} from '../pages/deviceReport/store'
import {reducre as deviceAnalyseReducer} from '../pages/deviceAnalyse/store'
import {reducre as reportReducer} from '../pages/Report/store'
import {reducre as loginReducer} from '../pages/Login/store'
import {reducre as alarmReducer} from '../pages/alarm/store'

import {reducre as psInfoReducer} from '../pages/PsInfo/components/psForm/store'
import {reducre as deviceManagementReducer} from '../pages/deviceManagement/store'
import {reducre as psTransReducer} from '../pages/PsTrans/store'
//import {reducre as deviceReportReducer} from '../pages/deviceReport/store'

// export default combineReducers({
//    // psinfoList:psinfoListReducer,
//     header:headerReducer,
//     menu:menuReducer,
//     home:homeReducer,
//     deviceReport:deviceReportReducer,
//     deviceAnalyse:deviceAnalyseReducer,
//     report:reportReducer,
//     login:loginReducer,
//     alarm:alarmReducer,
//     //deviceReport:deviceReportReducer
// })

const appReducer = combineReducers({
    /* your app’s top-level reducers */
    // psinfoList:psinfoListReducer,
    header:headerReducer,
    menu:menuReducer,
    home:homeReducer,
    deviceReport:deviceReportReducer,
    deviceAnalyse:deviceAnalyseReducer,
    report:reportReducer,
    login:loginReducer,
    alarm:alarmReducer,
    psInfo:psInfoReducer,
    deviceManagement:deviceManagementReducer,
    psTrans:psTransReducer
  })
  
  const rootReducer = (state, action) => {
    if (action.type === 'RESET_DATA') {
      //退出登录清空store中数据
      
      state = undefined
    }
  
    return appReducer(state, action)
  }


  export default rootReducer