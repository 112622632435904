import * as constants from './actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  deviceId:null,
  deviceType:null,
  list:[],
  isLoad:true,
})


const ReducreDR=(state=defaultState,action)=>{

  switch(action.type){
    case constants.DEVICEREPORT_DEVICE_LIST_LOAD:
      return state.set('isLoad',action.isLoad)//.set('deviceType',1);
    case constants.DEVICEREPORT_DEVICE_LIST:
      
      if(action.data.length>0){
          //return state.set('list',action.data).set('isLoad',false).set('deviceId',action.deviceId).set('deviceType',action.deviceType);
          //action.data[0]['deviceModule'])
        if(action.deviceId!==null){
          return state.set('list',action.data).set('isLoad',false).set('deviceId',action.deviceId).set('deviceType',action.deviceType).set('deviceModule',action.deviceModule);
        }else{
          return state.set('list',action.data).set('isLoad',false).set('deviceId',action.data[0]['deviceId']).set('deviceType',action.data[0]['deviceTypeId']).set('deviceModule',action.data[0]['deviceModule']);
        }
       
      }
      else
        return state.set('list',action.data).set('deviceId',null);
    case constants.DEVICEREPORT_DEVICE_CHECK:
      return state.set('deviceId',action.params.deviceId).set('deviceType',action.params.deviceType).set('deviceModule',action.params.deviceModule);
    default:
        return state;    
    
}   
}

export default ReducreDR