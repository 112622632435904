import * as actions from './actionTypes'

//获取点单个plc下设备列表
export const setDevices=(data)=>({
  type:actions.DEVICEMANAGEMENT_SREACH_DEVICE_INFO,
  data,
})

//设置采集器状态可点击或者不可点击
export const setCollectorStatus=(flag)=>({
  type:actions.DEVICEMANAGEMENT_COLLECTOR_DISABLED_STATUS,
  flag,
})


//点击添加设备状态可点击或者不可点击
export const setAddDeviceStatus=(flag)=>({
  type:actions.DEVICEMANAGEMENT_BUTTON_DISABLED_STATUS,
  flag,
})

