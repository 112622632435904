import * as actions from './actionTypes'



export const setVisiblePs=(data)=>({
    type:actions.SET_VISIBLE_PS,
    data

})

export const getVisiblePs=(data)=>({
    type:actions.GET_VISIBLE_PS,
    data

})


export const setVisibleType =(data)=>({
    type:actions.SET_VISIBLE_TYPE,
    data

})







