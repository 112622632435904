import * as actions from './actionTypes'

//保存电站设备类型信息
export const failureTypeList=(data,isLoad)=>({
  type:actions.FAILURETYPE_TYPES_LIST,
  data,
  isLoad
})
//异步加载电站设备类型信息
export const fetchFailureTypeList=()=>({
  type:actions.FETCH_FAILURETYPE_TYPES_LIST
})


//加载时是否显示加载中信息
export const failureTypeListLoad=(isLoad)=>({
  type:actions.FAILURETYPE_TYPES_LIST_LOAD,
  isLoad
})

//选中的设备类型信息集合
export const checkFailureTypeId=(failureTypeId)=>({
  type:actions.FAILURETYPE_TYPES_ID,
  failureTypeId
})
