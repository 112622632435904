import React from 'react'
import { connect } from 'react-redux'
 
import PsSearchBox from './psSearchBox/PsSearchBox'

import { SearchOutlined } from '@ant-design/icons'
import { SearchBarWrapper } from '../style'

import { globalActions } from '../store'
 
const SearchBar=(props)=> {
	return (
		<SearchBarWrapper className= {`search-bar flex-grow-1 ${ props.isFullSearchBar?'full-search-bar':'' }`}>
			<div className={`position-relative search-bar-box  ${ props.menuSel === '2333314RMFX7WB6D'?'Hide':'Show' }`}>
				<PsSearchBox />
				<span className='position-absolute top-50 search-show translate-middle-y'><SearchOutlined /></span>
				<span className='position-absolute top-50 search-close  translate-middle-y' onClick={props.closeSearch}><i className='bx bx-x'></i></span>
			</div>
		</SearchBarWrapper>
	)
}
const mapStateToProps=(state)=> {
	return {
		isFullSearchBar:state.getIn(['header','global','isFullSearchBar']),
		menuSel:state.getIn(['menu','selectedKeys']),
	}
}
const mapDippathToProps=(dispatch)=> {
	return {
		closeSearch(){
			dispatch(globalActions.setFullSearch(false))
		},
	}
}

export default connect(mapStateToProps,mapDippathToProps)(SearchBar);