import styled from 'styled-components'

export const WithBracketWrapper=styled.div`
  /* 括号样式 */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 16px;

  border-radius: 16px;
  border-left: 1px solid var(--bracket-color);
  border-right: 1px solid var(--bracket-color);
  background: transparent;

  padding: 0 2px;
  margin: 0 4px;
  box-sizing: border-box;

  opacity: 1;

  >span {
    transform: var(--size-small);
  }
`

export const WithRequiredMarkWrapper=styled.div`
  /* 红星号必填项标记样式 */
  >span.required-mark-optional::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: var(--tomato-100);
    font-size: var(--fontsize-14);
    font-family: 'Arial';
    font-weight: normal !important;
    line-height: 1.;
    content: '*';
    margin-right: 3.5px;
    margin-left: -9px;
  }
`

export const WithUnitWrapper=styled.div`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    >span.carrierContentStyle {}
    >span.prefixStyle,
    >span.suffixStyle {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 0;
      box-sizing: border-box;

      >span {
        transform: var(--size-normal);

        font-family: 'Arial Narrow';

        &.kWh::after,
        &.h::after,
        &.A::after,
        &.V::after {
          opacity: .65;
          margin: 0;
        }

        &.kWh::after {
          content: 'kWh';
        }
        &.h::after {
          content: 'h';
        }
        &.A::after {
          content: 'A';
        }
        &.V::after {
          content: 'V';
        }
      }

      /* 带括号的单位 */
      &.bracket {
        min-width: 20px;
        height: 14px;

        border-radius: 14px;
        border-left: 1px solid var(--bracket-color);
        border-right: 1px solid var(--bracket-color);
        background: transparent;

        padding: 0 3px;
        box-sizing: border-box;
        text-align: center;

        >span {
          transform: var(--size-small);
        }
      }
    }
`

export const LimitingWidthTextWrapper=styled.div`
  .ant-typography {
    display: flex;
    flex-direction: row;
    align-items: center;

    >span {
      display: block !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      width: fit-content !important;
    }
  }
` 

export const HelpCardWrapper=styled.div`
  .ant-typography {
    font-family: 'Arial Narrow';
    font-size: var(--fontsize-12);
    letter-spacing: var(--spacing-larger);
    text-align: justify;
  }
  .ant-divider {
    >.ant-divider-inner-text {
      font-size: var(--fontsize-14);
    }
  }
  span.textNote {
    color: var(--font-secondary);
    font-size: var(--fontsize-11);
  }
  span.textBracket {
    padding: 0 4px;
    margin: 0 4px;
    border-radius: 12px;
    border-left: 1px solid var(--bracket-color);
    border-right: 1px solid var(--bracket-color);
  }
  span.textTag {
    color: var(--black-100);
  }
  span.textOperation {
    margin: 0 2px;
    color: var(--sky-100);
    font-style: italic;
    &.textLead {
      margin: 0 2px 0 0;
    }
  }
  span.textImportant {
    margin: 0 2px;
    color: var(--tomato-100);
  }
`

export const HelpTipWrapper=styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-btn.ant-btn-circle.ant-btn-icon-only {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    width: 18px;
    min-width: 18px;
    height: 18px;
    cursor: auto;

    .anticon.anticon-question {
      width: 10px;
      height: 10px;
    }

    &:hover {
      .anticon.anticon-question {
        color: var(--sky-85);
      }
    }

    &.ant-btn-lg {
      width: 32px;
      min-width: 32px;
      height: 32px;
      .anticon.anticon-question {
        width: 16px;
        height: 16px;
      }
    }
  }
`