import {takeEvery,put, all} from 'redux-saga/effects'
import  {GET_USERINFOR_LIST } from './actionTypes'
import * as actionCreators from './actionCreators'
import { getUserInfo } from '../../../../../request/api'
import cookie from 'react-cookies'

function* saga(){

    yield all([
        yield takeEvery(GET_USERINFOR_LIST, fetchSearchList),

    ])
}

function* fetchSearchList(){
    const userId =cookie.load('userId');// window.sessionStorage.getItem("userId");
    try{
        const res=yield getUserInfo(userId);//service-user/user/getAccountInfo?userId='+userId
        if(res.status===200){
            const data=res.data;
            const action = actionCreators.setUserInfoList(data)
            yield put(action);
        }
        
    }
    catch(e){
        console.log("请求失败");
        
    }
    
}


export default saga;



