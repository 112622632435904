import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cookie from 'react-cookies'
//i18next-browser-languagedetector插件 
//这是一个 i18next 语言检测插件，用于检测浏览器中的用户语言，
//详情请访问：https://github.com/i18next/i18next-browser-languageDetector
//import LanguageDetector from 'i18next-browser-languagedetector';
//引入需要实现国际化的简体、繁体、英文三种数据的json文件
import cn from './zh-CN.json'
import ja from './ja_JP.json'
import en from './en_US.json'
const resources = {
  zhCN: {
    translation: cn
  },
  jaJP: {
    translation: ja
  },
  enUS: {
    translation: en
  }

};
let cookieLng = cookie.load('language');
let defaultLng;
if (typeof (cookieLng) === 'undefined') {
  let lan = navigator.systemLanguage || navigator.language;

  if (lan.toLowerCase().indexOf('zh') !== -1) {
    document.getElementsByTagName("html")[0].setAttribute("lang", "zh");
    //console.log('当前浏览器默认语言为中文')
    defaultLng = 'zhCN'
  } else if (lan.toLowerCase().indexOf('en') !== -1) {
    //console.log('当前浏览器默认语言为英文')
    defaultLng = 'enUS'
    document.getElementsByTagName("html")[0].setAttribute("lang", "en");
  }
  else {
    defaultLng = 'jaJP'
    document.getElementsByTagName("html")[0].setAttribute("lang", "ja");
  }
}
else {
  defaultLng = cookieLng;
  switch (defaultLng) {
    case 'jaJP': document.getElementsByTagName("html")[0].setAttribute("lang", "ja"); break;
    case 'enUS': document.getElementsByTagName("html")[0].setAttribute("lang", "en"); break;
    case 'zhCN': document.getElementsByTagName("html")[0].setAttribute("lang", "zh"); break;
  }
}

i18n.use(initReactI18next) // 将 i18n 向下传递给 react-i18next
  .init({ //初始化
    resources, //本地多语言数据
    // 默认语言  zh/en  中文/英文
    lng: defaultLng,
    interpolation: {
      escapeValue: true,
    },
  })

export default i18n