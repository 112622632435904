import * as constants from './actionTypes';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import {reducre as systemChartsReducer} from '../components/SystemCharts/store/index.js';
import {reducre as deviceSearchBoxReducer} from '../components/DeviceSearchBox/store/index.js';
import {reducre as deviceTypeListReducer} from '../components/DeviceTypeList/store/index.js';
import {reducre as visiblePsReducer} from '../components/VisibleSetting/store/index.js';

const defaultState=fromJS({
    list:false,
    visiblePs: [],
});

// eslint-disable-next-line import/no-anonymous-default-export
const homeGlobalReducer = (state = defaultState,action) =>{

  switch(action.type){
    case constants.SET_VISIBLE_PS:
        return state.set('visiblePs', action.data);
    case constants.HOME_LIST:
        return state.set('isFullSearchBar',action.data);
    default:
      return state;
  }
}




const defaultCombine = combineReducers({
  global:homeGlobalReducer,
  systemChart:systemChartsReducer,
  deviceSearchBox:deviceSearchBoxReducer,
  deviceTypeList:deviceTypeListReducer,
  visibleSetting:visiblePsReducer
})

export default defaultCombine