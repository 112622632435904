import * as constants from './actionTypes';
import { fromJS } from 'immutable';

const defaultState = fromJS({
    isLoad: true,
    defaultValue: '',
    psInfo: {},
    list: [],
    allPs:false,
});

const ReducreFn = (state = defaultState, action) => {
    switch (action.type) {
        case constants.PSINFO_LIST_LOAD:
            return state.set('isLoad', action.isLoad);
        case constants.SET_PSINFOR_LIST:
            //alert(action.data.length)
            return state.set('list', action.data).set('isLoad', false);
        case constants.SELECT_PS:
            return state.set('defaultValue', action.data);
        case constants.SET_PSINFO:
            return state.set('psInfo', action.data);
        case constants.SET_ALLPS:
            return state.set('allPs', action.allPs);
        default:
            return state
    }
}

export default ReducreFn