import { takeEvery, put, all } from 'redux-saga/effects'
import { GET_DEVICE_TYPE_LIST } from './actionTypes'
import * as actionCreators from './actionCreators'
import { getDeviceTypeByPsId } from '../../../../../request/api'



function* saga() {

    yield all([
        yield takeEvery(GET_DEVICE_TYPE_LIST, fetchSearchList),

    ])
}
function* fetchSearchList(action) {
    try {
        console.log('112222233333333',action)
        const res = yield getDeviceTypeByPsId(action.data);
        if (res.status === 200) {
            const data = res.data;
            const action = actionCreators.setDeviceTypeList(data)
            yield put(action);
        }
    }
    catch (e) {
        console.log("请求失败");

    }

}


export default saga;


