import * as constants from './actionTypes';
import { combineReducers } from 'redux-immutable';

import { fromJS } from 'immutable';

const defaultState = fromJS({
    isUpdate: 0,
    psinfo: {},

});

const ReducreFn = (state = defaultState, action) => {
    switch (action.type) {
        case constants.PSINFO_UPDATE:
            return state.set('isUpdate', action.isUpdate);
        case constants.SET_PSINFO:
            return state.set('psinfo', action.data);
        default:
            return state;

    }
}

const defaultCombine = combineReducers({
    psForm: ReducreFn,

})


export default defaultCombine







