import React,{useEffect,lazy} from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cookie from 'react-cookies'

import { SearchOutlined } from '@ant-design/icons'
import { TopMenuWrapper, SearchMini } from '../style'

import * as actionCreators from '../store/actionCreators'

const LanguageChooser = lazy(() => import(/* webpackChunkName: 'chooselanguage' */'../../chooselanguage'))
  
const TopMenu=(props)=>{  
  const { i18n } = useTranslation();
	useEffect(()=>{
	},[])//eslint-disable-line

  const getLanguage = (LangeuageId) => {
    switch (LangeuageId) {
      case 'zhCN':
        document.getElementsByTagName('html')[0].setAttribute('lang','zh');
        i18n.changeLanguage('zhCN');
        cookie.save('language','zhCN',{ path: '/' });
        props.handleLanguage('zh-cn')
        break;
      case 'enUS':
        document.getElementsByTagName('html')[0].setAttribute('lang','en');
        i18n.changeLanguage('enUS');
        cookie.save('language','enUS',{ path: '/' });
        props.handleLanguage('en-us')
          break;
      case 'jaJP':
        document.getElementsByTagName('html')[0].setAttribute('lang','ja');
        i18n.changeLanguage('jaJP');
        cookie.save('language','jaJP',{ path: '/' });
        props.handleLanguage('ja-jp')      
        break;
      default:
        break;
    }
  }
 
  return(
    <TopMenuWrapper>
      <SearchMini onClick={props.openSearch}>
        <SearchOutlined />
      </SearchMini>
      <LanguageChooser shape='round' getLanguage={getLanguage} />
    </TopMenuWrapper>
  )
}
const mapStateToProps=(state)=>{
  return{
    isFullSearchBar:state.getIn(['header','isFullSearchBar']),
    language:state.getIn(['header','global','language']),
  }
}
const mapDippathToProps=(dispatch)=>{
  return{
    openSearch(){
      dispatch(actionCreators.setFullSearch(true))
    },
    handleLanguage(language){
      dispatch(actionCreators.setLocal(language))
    }
  }
}

export default connect(mapStateToProps,mapDippathToProps)(TopMenu) ;