import * as constants from './actionTypes';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import {reducre as psinfoListReducer} from '../components/psSearchBox/store';
import {reducre as userBoxReducer} from '../components/userCenter/store';

const defaultState=fromJS({
  isFullSearchBar:false,
  language:null,
  isMobileMenuFlag:false,
});

// eslint-disable-next-line import/no-anonymous-default-export
const headerGlobalReducer = (state = defaultState,action) =>{
  switch(action.type){
    case constants.SET_FULL_SEARCH:
      return state.set('isFullSearchBar',action.data);
    case constants.SET_LOCALE:
      return state.set('language',action.language);
    case constants.SET_MOBILE_MENU:
      return state.set('isMobileMenuFlag', action.isFlag); 
    default:
      return state;
  }
}

const defaultCombine = combineReducers({
  global:headerGlobalReducer,
  psinfoList:psinfoListReducer,
  userinfoList:userBoxReducer
})

export default defaultCombine