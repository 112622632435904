import {takeEvery,put, all} from 'redux-saga/effects'
import  {GET_PSINFO } from './actionTypes'
import * as actionCreators from './actionCreators'
import { getPsInfoSmartomById } from '../../../../../request/api'

function* saga(){

    yield all([
        yield takeEvery(GET_PSINFO, fetchSearchList),

    ])
}

function* fetchSearchList(action){

    try{
        const res=yield getPsInfoSmartomById(action.psId);
        if(res.status===200){
          
            const action = actionCreators.setPsinfo(res.data)
            yield put(action);
           
        }
        else if(res.status===503){
            //window.location.href='/'
            //const urlRoute = useNavigate();
            //urlRoute("/")
        }
        
    }
    catch(e){
        console.log("请求失败");
        
    }
    
}


export default saga;