import styled from 'styled-components'

export const Wrapper=styled.div`
  width: 100%;
  position: relative;
`

export const PageWrapper=styled.div`
	height: 100%;
	padding-top: 60px;
	margin-bottom: 30px;
	margin-left: 250px;
  left: 250px;
  transition: var(--transition-normal);
  &.toggled {
    margin-left: 80px;
    left: 80px;
  }
  @media (max-width:1024px) {
    margin-left: 0;
    left: 0 !important;
  }
`

export const PageContent=styled.div`
  padding: 1.5rem;
`