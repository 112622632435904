import * as constants from './actionTypes';
import { fromJS } from 'immutable';

const defaultState = fromJS({
    list: null,
});

const ReducreFn = (state = defaultState, action) => {
    switch (action.type) {
        case constants.SET_PS_USER_ROLE:
            return state.set('list', action.data);
        case constants.SET_SEL_PS:
            return state.set('selPs', action.data);
        default:
            return state;

    }
}


export default ReducreFn