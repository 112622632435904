import * as constants from './actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  deviceList: [],
  isSyncStatus: false,//是否同步设备
  isAddStatus: true,//是否可以点击添加设备
})

const ReducreDR = (state = defaultState, action) => {
  switch (action.type) {
    case constants.DEVICEMANAGEMENT_SREACH_DEVICE_INFO:
      return state.set('deviceList', action.data)
    case constants.DEVICEMANAGEMENT_COLLECTOR_DISABLED_STATUS:
      return state.set('isSyncStatus', action.flag)
    case constants.DEVICEMANAGEMENT_BUTTON_DISABLED_STATUS:
      return state.set('isAddStatus', action.flag)
    default:
      return state;
  }
}
export default ReducreDR