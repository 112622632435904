import { legacy_createStore as createStore, compose ,applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducer from './reducer';
import { sagas as headerSagas } from '../common/header/store'
import { sagas as menuSagas } from '../common/menu/store'
import { sagas as homeSagas } from '../pages/Home/store'

import { sagas as deviceSagas } from '../pages/deviceReport/store'
import { sagas as reportSagas } from '../pages/Report/store'

import { sagas as deviceAnalyseSagas } from '../pages/deviceAnalyse/store'
import { sagas as alarmSagas } from '../pages/alarm/store'

import { sagas as loginSagas } from '../pages/Login/store'

import {sagas as psFormSagas} from '../pages/PsInfo/store'

import {sagas as psTransSagas} from '../pages/PsTrans/store'
//import { sagas as deviceReportSagas } from '../pages/deviceReport/store'
const sagaMiddleware=createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store=createStore(reducer,composeEnhancers(
    applyMiddleware(sagaMiddleware)
));
let sagas=[...headerSagas,...homeSagas,...deviceSagas,...loginSagas,...deviceAnalyseSagas,...reportSagas,...alarmSagas,...psFormSagas,...psTransSagas];
for(let i=0;i<sagas.length;i++){
    sagaMiddleware.run(sagas[i]);
}




// const sagasLogin=[...loginSagas];
// for(let i=0;i<sagasLogin.length;i++){
//     sagaMiddleware.run(sagasLogin[i]);
// }
//sagaMiddleware.run(loginSagas);
sagaMiddleware.run(menuSagas);


export default store