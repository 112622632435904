import * as constants from './actionTypes';
import { fromJS } from 'immutable';

const defaultState=fromJS({
  isLoad:true,
  user:[]
});

const ReducreFn=(state=defaultState,action)=>{

    switch(action.type){
        case constants.GET_USERINFOR_LOAD:
            return state.set('isLoad', action.isLoad);
        case constants.SET_USERINFOR_LIST:
            return state.set('user',action.data).set('isLoad', false);
        default:
            return state;    
        
    }
}

export default ReducreFn