import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next' //语言包
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import cookie from 'react-cookies'

import { TopBar, NavBar, NavBarRight } from './style'

import MobileToggleMenu from './components/MobileToggleMenu'
import SearchBar from './components/SearchBar'
import TopMenu from './components/TopMenu'
import UserInfoList from './components/userCenter'
import RegisterPsInfo from './components/registerPsInfo'

const Header = (props) => {
  const userId = cookie.load('userId'); //window.sessionStorage.getItem('userId');
  //if(typeof(userId)==='undefined')
  const { t } = useTranslation();
  useEffect(() => {
    props.handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const registerRef = useRef();
  //没有绑定过电站的用户，进入引导模式

  // const tran = () => {
  //   var tempwindow = window.open('_blank'); // 先打开页面
  //   tempwindow.location = 'https://beta.smartom.com'; // 后更改页面地址
  // };

  if (typeof userId === 'undefined') {
    return <Navigate to='/timeout' />;
  } else if (userId === null) {
    return <Navigate to='/timeout' />;
  }
  return (
    <>
      <TopBar className={`${props.collapsed ? 'toggled' : ''}`}>
        <NavBar>
          <MobileToggleMenu />
          <SearchBar />
          <NavBarRight>
            <RegisterPsInfo />
            <TopMenu />
            <UserInfoList />
          </NavBarRight>
        </NavBar>
      </TopBar>

      {/* 绑定电站弹出窗 */}
      {/* <RegisterPsInfo ref={registerRef} /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    collapsed: state.getIn(['menu', 'collapsed']),
  };
};
const mapDippathToProps = (dispatch) => {
  return {
    handleLoad() { },
  };
};

export default connect(mapStateToProps, mapDippathToProps)(Header);