import * as constants from './actionTypes';
import { fromJS } from 'immutable';

const defaultState = fromJS({
    defaultValue: '',
    deviceList: []
});

const ReducreFn = (state = defaultState, action) => {
    switch (action.type) {
        case constants.SELECT_DEVICE_TYPE:
            return state.set('defaultValue', action.data);
        case constants.SET_DEVICE_TYPE_LIST:
            return state.set('deviceList', action.data);
        default:
            return state;

    }
}

export default ReducreFn