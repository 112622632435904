import * as actions from './actionTypes'

export const checkShearchDate=(searchDate)=>({
    type:actions.DEVICEREPORT_SEARCH_DATE,
    searchDate
})


export const checkShearchType=(searchType)=>({
    type:actions.DEVICEREPORT_SEARCH_TYPE,
    searchType
})
export const initDatePicker=(searchType,searchDate)=>({
    type:actions.DEVICEREPORT_SEARCH_INIT,
    searchType,
    searchDate
})


