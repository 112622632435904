

//历史搜索数据绑定action
export const HOME_LIST='home/HOME_LIST';
//SAGA查询历史数据action
export const FETCH_HOME_LIST='home/FETCH_HOME_LIST';

export const GET_VISIBLE_PS='home/devicetypelist/GET_VISIBLE_PS';

export const SET_VISIBLE_PS='home/devicetypelist/SET_VISIBLE_PS';
