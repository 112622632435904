import * as constants from './actionTypes'

export const searchList=(data)=>({
    type:constants.HOME_LIST,
    data
})

export const fetchSearchList=(data)=>({
    type:constants.FETCH_HOME_LIST,
    data
})

export const setVisiblePs=(data)=>({
    type:constants.SET_VISIBLE_PS,
    data

})

export const getVisiblePs=(data)=>({
    type:constants.GET_VISIBLE_PS,
    data

})