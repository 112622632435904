import * as constants from './actionTypes';
import { fromJS } from 'immutable';

const defaultState=fromJS({
  setPsIds:''
});

const ReducreFn=(state=defaultState,action)=>{

    switch(action.type){
        case constants.SELECT_PSIDS:
            return state.set('setPsIds',action.setPsIds);
        default:
            return state;    
    }
}

export default ReducreFn