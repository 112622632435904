import * as constants from '../store/actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  isLoad: true,
  deviceTypeId: 0,
  deviceTypes: [],
})

const ReducreDR = (state = defaultState, action) => {
  switch (action.type) {
    case constants.ALARM_DEVICEANALYSE_DEVICE_TYPES_LOAD:
      return state.set('isLoad', action.isLoad);
    case constants.ALARM_DEVICEANALYSE_DEVICE_TYPES:
      if (action.data.length > 0) {
        return state.set('deviceTypes', action.data).set('isLoad', false).set('deviceTypeId', 0);
      } else {
        return state.set('deviceTypes', []).set('isLoad', false)
      }
    case constants.ALARM_DEVICEANALYSE_DEVICE_TYPE_ID:
      return state.set('deviceTypeId', action.deviceTypeId)
    default:
      return state;
  }
}
export default ReducreDR