import {takeEvery,put, all} from 'redux-saga/effects'
import  {GET_PSINFOR_LIST } from './actionTypes'
import * as actionCreators from './actionCreators'
import { getPsInfoByUserId,getRidesPsInfoAliasName } from '../../../../../request/api'
import cookie from 'react-cookies'

function* saga(){

    yield all([
        yield takeEvery(GET_PSINFOR_LIST, fetchSearchList),

    ])
}

function* fetchSearchList(){

    
    const userId =cookie.load('userId');// window.sessionStorage.getItem("userId");
    const psId= window.sessionStorage.getItem("psId");
    var actionPsinfo=null;
    var actionDefault=null;
    try{
        const res=yield getPsInfoByUserId(userId);
        if(res.status===200){
            var data=res.data;
          
             //设置别名
             const ret = yield getRidesPsInfoAliasName(userId);
             const ridesPsInfoAlias = ret.data;
             if(ridesPsInfoAlias!==null){
                ridesPsInfoAlias.forEach(alias => {
                    data.forEach(item => {
                        if(alias.psId===item.psId){
                            if(alias.aliasName!=="")
                            item.psName=alias.aliasName;
                           
                        }
                    });
     
                  })
             }
             //电站名称排序
            
             data.sort(function(a,b){

                return a.psName.localeCompare(b.psName)
   
            })
            const action = actionCreators.setPsInfoList(data)
            yield put(action);
            if(data!=null){
               
                //设置电站默认值
                if(psId!=null){
                    data.forEach(item => {
                        if(item.psId===psId){
                             actionPsinfo = actionCreators.setPsinfo(item)
                             actionDefault = actionCreators.selectPs(item.psId)
                           
                        }
                    });
                }else{
                     actionPsinfo = actionCreators.setPsinfo(data[0])
                     actionDefault = actionCreators.selectPs(data[0].psId)
                }
                yield put(actionPsinfo);
                yield put(actionDefault);
              
            }
        }
        else if(res.status===503){
            //window.location.href='/'
            //const urlRoute = useNavigate();
            //urlRoute("/")
        }
        
    }
    catch(e){
        console.log("请求失败");
        
    }
    
}


export default saga;