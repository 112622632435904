import React from 'react'
import { ReactSVG } from 'react-svg'
import './style.css'

// const svgUrl = { 'fal': 'light', 'fad': 'duotone', 'far': 'regular', 'fas': 'solid', 'fab': 'brands' }
const svgUrl = { 'fal': 'light', 'fad': 'duotone' }

const svgType = (type) => {
  let ctx;
  switch (type) {
    case 'fal':
      ctx = require.context('../../statics/svg/light/', true, /\.svg$/)
      break;
    case 'fad':
      ctx = require.context('../../statics/svg/duotone/', true, /\.svg$/)
      break;
    // case 'far':
    //   ctx = require.context('../../statics/svg/regular/', true, /\.svg$/)
    //   break;
    // case 'fas':
    //   ctx = require.context('../../statics/svg/solid/', true, /\.svg$/)
    //   break;
    // case 'fab':
    //   ctx = require.context('../../statics/svg/brands/', true, /\.svg$/)
    //   break;
  }
  return ctx;

}
const IconSvg = (props) => {
  const map = {}
  const icons = props.icon.split(' ');

  let icon = '';

  if (icons.length > 1) {
    // console.log('icon',icons[0],icons[1])
    icon = icons[1]
    const ctx = svgType(icons[0])
    //const ctx = require.context('../../statics/svg/solid/', true, /\.svg$/)
    for (const key of ctx.keys()) {
      map[key] = ctx(key)
    }
    //console.log('icon',icon,'url',map['./' + icon.replace('fa-', '') + '.svg'])
  }
  else {
    icon = icons[0]
    const ctx = require.context('../../statics/svg/light/', true, /\.svg$/)
    for (const key of ctx.keys()) {
      map[key] = ctx(key)
    }
  }
  // console.log('maps',map);
  // console.log('icon',icon);
  // console.log('srcmap',map['./' + icon.replace('fa-', '') + '.svg']);
  return (
    <>
      <ReactSVG className={`${props.className} ${typeof (props.size) != 'undefined' ? props.size : ''} icon-svg `} src={map['./' + icon.replace('fa-', '') + '.svg']} />
      {/* style={{fill:props.color,fontSize:props.fontSize+'px'}} */}
    </>
  )
}

export default IconSvg