import * as actions from './actionTypes'
import { fromJS } from 'immutable';

const defaultState = fromJS({
  countryList:[],
})

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState,action) =>{
  switch(action.type){
    case actions.SET_COUNTRY_LIST:
        return state.set('countryList',action.data).set('networkError',false) 
    case actions.LOGIN_NETWORK_ERROR:
      return state.set('networkError',true)
    default:
        return state;
  }
}