import * as constants from './actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  isLoad: true,
  clickYearDate: null,
  failureTypeId:null,
  randomYearNum:0,
})

const ReducreDR = (state = defaultState, action) => {
  switch (action.type) {
    case constants.ALARM_CHOOSETYPE_CLICK_YEAR_DATE:
      return state.set('clickYearDate', action.clickDate).set('randomYearNum', Math.floor(Math.random() * 999999));;
    case constants.ALARM_CHOOSETYPE_FAILURE_TYPE_ID:
        return state.set('failureTypeId', action.failureTypeId);  
    default:
      return state;
  }
}
export default ReducreDR