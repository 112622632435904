//导入我们封装好的axios 
import service from './index'

//用户登录接
export const login = userInfo => service.post('service-user/user/login', userInfo);


//用户登录接
export const loginAli = userInfo => service.post('service-user/user/loginAli', userInfo); 

//用户ID获取电站列表
export const getPsInfoByUserId = userId => service.get('service-psinfo/psinfo/getPsInfoByUserId?userId='+userId);

//电站首页运行图
export const getPsSoipDatas = psId => service.get('service-psinfo/psdata/getPsSoipDatas?psId='+psId);


//获取验证码
export const userCodeOrEmail = (userCodeOrEmail,languageId) => service.post('service-user/user/forgetPassword?userCodeOrEmail='+userCodeOrEmail+"&languageId="+languageId);

//验证码修改用户密码
export const updatePassword = info => service.post('service-user/user/updatePassword',info);

//验证码修改用户密码（带用户ID验证）
export const updatePasswordByUser = info => service.post('service-user/user/updatePassword',info);


//获取电站下所有设备
export const getDevicesByPsIdTypeId = psId => service.get('service-psinfo/psinfo/getDevicesByPsIdTypeId?deviceTypeId=&psId='+psId);

export const getDevicesByPsIdTypeId2 = (psId,deviceTypeId) => service.get('service-psinfo/psinfo/getDevicesByPsIdTypeId?deviceTypeId='+deviceTypeId+'&psId='+psId);

//获取电站下所有设备分组
export const getDeviceTypeByPsId = psId => service.get('service-psinfo/psinfo/getDeviceTypeByPsId?psId='+psId);

//获取电站下DCDC明细数据
export const getDcdcDetailsByPsid = psId => service.get('service-device/dcdc/getDcdcDetailsByPsid?psId='+psId);

//获取电站下蓄电池明细数据
export const getRackDetailsByPsid = psId => service.get('service-device/rack/getRackDetailsByPsid?psId='+psId);

//获取电站下PCS明细数据
export const getPcsDetailsByPsid = psId => service.get('service-device/pcs/getPcsDetailsByPsid?psId='+psId);

//获取电站下空调明细数据
export const getAirDetailsByPsid = psId => service.get('service-device/air/getAirDetailsByPsid?psId='+psId);

//获取电站下电表明细数据
export const getAmmeterDetailsByPsid = psId => service.get('service-device/ammeter/getAmmeterDetailsByPsid?psId='+psId);

//获取电站统计数据
export const getPsTotalData = psId => service.get('service-psinfo/psdata/getPsTotalData?psId='+psId);

//获取电站天气信息
export const getWeatherByPsId = psId => service.get('service-psinfo/psdata/getBetweenWeatherByPsId?psId='+psId);

//获取电站统计数据显示设置
export const getPsDataSettings = psId => service.get('service-psinfo/psdata/getPsDataSettings?psId='+psId);

//获取电站下某设备类型的月统计数据
export const getDeviceDaysByPsidTypeid = (psId,deviceTypeId,searchDate) => service.get('service-device/pssys/getDeviceDaysByPsidTypeid?psId='+psId+'&deviceTypeId='+deviceTypeId+'&searchDate='+searchDate);

//获取电站下某设备类型的日统计数据
export const getDeviceDetailsByPsidTypeid = (psId,deviceTypeId,searchDate) => service.get('service-device/pssys/getDeviceDetailsByPsidTypeid?psId='+psId+'&deviceTypeId='+deviceTypeId+'&searchDate='+searchDate);

//获取电站下月统计数据
export const getDeviceMonthAmountByPsid = (psId,psTypeId,searchDate) => service.get('service-device/pssys/getDeviceMonthAmountByPsid?psId='+psId+'&psTypeId='+psTypeId+'&searchDate='+searchDate);

//获取电站下日统计数据
export const getDeviceDayPacsByPsid = (psId,psTypeId,searchDate) => service.get('service-device/pssys/getDeviceDayPacsByPsid?psId='+psId+'&psTypeId='+psTypeId+'&searchDate='+searchDate);


//保存电站统计数据显示设置
export const savePsDataSettings = (psId,psDataVisibleBoList) => service.post('service-psinfo/psdata/savePsDataSettings?psId='+psId,psDataVisibleBoList);

//注册用户接口
export const registerUser = (languageId,regData) => service.post('service-user/user/register?languageId='+languageId,regData);

//发送邮件
export const sendUserEmail = (userCode,languageId) => service.get('service-user/user/sendActiveMail?userCode='+userCode
+'&languageId='+languageId);


//用户数据更新
export const updateUserInfo = data => service.post('service-user/user/updateUserInfo',data);

//用户修改密码
export const updatePwd = data => service.post('/service-user/user/updatePwd',data);

//退出
export const logout = userId => service.get('service-user/user/logout?userId='+userId);

//获取国家
export const getCountrys = () => service.get('service-base/country/getCountrys');

//获取省份
export const getProvinces = countryId => service.get('service-base/province/getProvinces?countryId='+countryId);

//获取城市
export const getCitys = provinceId => service.get('service-base/base/getCitys?provinceId='+provinceId);

//获取电站信息
export const getPsInfoSmartomById = psId=> service.get('service-psinfo/psinfo/getPsInfoSmartomById?psId='+ psId)

//获取电站别称
export const getRidesPsInfoAliasName  = userId=> service.get('service-psinfo/psinfo/getRidesPsInfoAliasName?userId='+ userId)

//修改电站别称
export const addUpdateRidesPsAliasName = data => service.post('service-psinfo/psinfo/addUpdateRidesPsAliasName',data)

//修改电站信息
export const updatePsInfo = data => service.post('service-psinfo/psinfo/update',data)  

//获取电站类型
export const getPsTypes  = ()=> service.get('service-base/psType/getPsTypes')

//获取电站时区
export const getTimezones  = ()=> service.get('service-base/timezone/getTimezones')

//获取电站显示配置
export const getPsVisible  = (psId,psTypeId)=> service.get('service-base/psType/getPsVisible?psId='+psId+'&psTypeId='+psTypeId)

//保存电站显示配置
export const savePsVisible = (psId,data) => service.post('service-base/psType/savePsVisible?ps_id='+ psId,data)  


//告警月统计
export const getPsAlarmMonthDaysBypsId = (psId,searchDate) =>service.get('service-device/api/error/getPsAlarmMonthDaysBypsId?psId='+psId+'&searchDate='+searchDate)

//告警年统计
export const getPsAlarmYearMonthsBypsId = (psId,searchDate) =>service.get('service-device/api/error/getPsAlarmYearMonthsBypsId?psId='+psId+'&searchDate='+searchDate)

//告警故障列表
export const getCustomErrorDetail = (psId,searchDate,currentPage,pageCount,userId,deviceTypeId,languageId) =>service.get('service-warning/warning/getCustomErrorDetail?currentPage='+currentPage+'&pageCount='+pageCount+'&userId='+userId+'&psId='+psId+'&searchDate='+searchDate+'&deviceTypeId='+deviceTypeId+'&languageId='+languageId)

//告警通讯异常列表
export const getAbnormalCommunicationDetail = (psId,searchDate,currentPage,pageCount,userId) =>service.get('service-warning/warning/getAbnormalCommunicationDetail?currentPage='+currentPage+'&pageCount='+pageCount+'&userId='+userId+'&psId='+psId+'&searchDate='+searchDate)

//告警设备离线列表
export const getEquipmentOfflineDetail = (psId,searchDate,currentPage,pageCount,userId,deviceTypeId) =>service.get('service-warning/warning/getEquipmentOfflineDetail?currentPage='+currentPage+'&pageCount='+pageCount+'&userId='+userId+'&psId='+psId+'&searchDate='+searchDate+'&deviceTypeId='+deviceTypeId)


//获取邮件设置
export const getUserAlertEmailSendList = (userId) =>service.get('service-user/UserAlterEmailSend/getUserAlertEmailSendList?userId='+userId)


//获取邮件报警类型
export const getFailureTypes = () =>service.get('service-base/failureType/getFailureTypes')

//修改报警邮箱设置
export const updateUserAlertEmailSend = data =>service.post('service-user/UserAlterEmailSend/updateUserAlertEmailSend',data)


//删除报警邮箱设置
export const deleteUserAlertEmail = (userId,userAlertId) =>service.get('service-user/UserAlterEmailSend/deleteUserAlertEmail?userId='+userId+"&userAlertId="+userAlertId)


//添加报警邮件设置
export const addUserAlertEmailSend = data =>service.post('service-user/UserAlterEmailSend/addUserAlertEmailSend',data)


//发送报警警邮件设置
export const sendTestMail = (email,languageId) =>service.get('service-user/user/sendTestMail?email='+email+"&languageId="+languageId)

//根据用户ID获取用户信息
export const getUserInfo = (userId) =>service.get('service-user/user/getAccountInfo?userId='+userId)

//根据用户ID获取菜单 
export const getMenus = (userId) =>service.get('service-user/menu/getMenusByRoleId?userId='+userId)

//用户注册电站
export const addUserPsRole = data =>service.post('service-psinfo/psinfo/addUserPsRole',data)

//验证用户邮箱是否存在
export const userEmailIsExist = (userEmail) => service.get('service-user/user/userEmailIsExist?userEmail='+userEmail)

//验证电站ID或采集器序列号是否存在是否被使用
export const isExistPmuOrPs = (psIdSn) => service.get('service-psinfo/pmu/isExistPmuOrPs?ps_id_sn='+psIdSn)

//验证采集器SN是否存在、是否被使用、是否为解绑设备
export const isExistBindPmu = (sn) => service.get('service-psinfo/pmu/isExistBindPmu?sn='+sn)

//注册电站信息
export const registerByPmu = (userId,data) =>service.post('service-psinfo/psinfo/registerByPmu?userId='+userId,data)

//电站ID查询采集器列表
export const getPmuByPsId = (psId) => service.get('service-psinfo/pmu/getPmuByPsId?ps_id='+psId)

//根据用户ID、电站ID、采集器ID查询设备列表(分页)
export const getDevicePageListSmartOM = (userId,psId,pmuId,languageId,page,pageSize) => service.get('service-psinfo/psinfo/getDevicePageListSmartOM?userId='+userId+'&psId='+psId+'&pmuId='+pmuId+'&languageId='+languageId+'&page='+page+'&pageSize='+pageSize)

//修改采集器自定义名称
export const updatePmuName = (data) =>service.post('service-psinfo/pmu/updatePmuName',data)

//新添加采集器
export const addPmu = (data) =>service.post('service-psinfo/pmu/save',data)

//变更采集器
export const updatePmu = (pmu_id,pmu_sn,ps_id,user_id) =>service.get('service-psinfo/pmu/updatePmu?pmu_id='+pmu_id+'&pmu_sn='+pmu_sn+'&ps_id='+ps_id+'&user_id='+user_id)


//查询品牌信息
export const getBrandsByDevTypeId = (deviceTypeId) =>service.get('service-base/brand/getBrandsByDevTypeId?deviceTypeId='+deviceTypeId)

//查询型号信息
export const getModelsByBrandId = (brandId) =>service.get('service-base/model/getModelsByBrandId?brandId='+brandId)

//查询型号信息
export const getProtocolPlcDeviceTypeList = (protocolId) =>service.get('service-base/protocolPlcDevice/getProtocolPlcDeviceTypeList?protocolId='+protocolId)

//获取当前PLC设备的最大地址码
export const getMaxDeviceAddressPlc = (psId,pmuId) =>service.get('service-psinfo/psinfo/getMaxDeviceAddressPlc?psId='+psId+'&pmuId='+pmuId)

//同步设备
export const batchSaveDevice = (data) =>service.post('service-psinfo/psinfo/batchSaveDevice',data)

//根据电站ID、采集器ID查询PLC设备列表
export const getDevicePlcListById = (psId,pmuId) =>service.get('service-psinfo/psinfo/getDevicePlcListById?psId='+psId+'&pmuId='+pmuId)

//根据用户ID、电站ID、采集器ID查询设备列表
export const getDeviceListSmartOMByPlcId = (psId,pmuId,languageId) => service.get('service-psinfo/psinfo/getDeviceListSmartOMByPlcId?psId='+psId+'&pmuId='+pmuId+'&languageId='+languageId)

//根据型号ID查询型号信息
export const getModelById = (modelId) =>service.get('service-base/model/getModelById?modelId='+modelId)

//设备类型信息
export const getDeviceTypes = () =>service.get('service-base/deviceType/getDeviceTypes')

//根据设备ID修改PLC设备列表
export const updateDevName = (deviceId,deviceName) =>service.get('service-psinfo/psinfo/updateDevName?deviceId='+deviceId+'&deviceName='+deviceName)


//判断采集器序列号是否存在（含解绑验证）
export const isExistPmuSn = (sn) => service.get('service-psinfo/pmusn/isExistPmuSn?sn='+sn)



//添加采集器(含解绑设备)
export const savePmu = (data) =>service.post('service-psinfo/pmu/savePmu',data)

//根据用户Id获取电站带角色
export const getPsUserRoleByUserId = (userId,psName,role,page,pageSize) => service.get('service-psinfo/psinfo/getPsUserRoleByUserId?userId='+userId+'&role='+role+'&psName='+psName+'&page='+page+'&pageSize='+pageSize)

//根据电站ID获取用户带角色
export const getUserRoleByPsId = (userId,psId) => service.get('service-psinfo/userPsRole/getUserRoleByPsId?userId='+userId+'&psId='+psId)

//修改用户的电站角色
export const updateUserRole = (data) => service.post('service-psinfo/userPsRole/update',data)

//删除用户的电站角色
export const deleteUserRole = (userRoleId,userId,psId,operator) => service.delete('service-psinfo/userPsRole/delete?userRoleId='+userRoleId+'&userId='+userId+'&psId='+psId+'&operator='+operator)

//userCode获取用户信息
export const getUserInfoByUserCode = userCode => service.get('service-user/user/getUserInfoByUserCode?userCode='+userCode)

//电站转让
export const psTrans = (data) => service.post('service-psinfo/userPsRole/psTrans',data)

//采集器下是否存在该设备名称
export const isDeviceNameByPmuId = (pmuId,deviceId,deviceName) => service.get('service-psinfo/psinfo/isDeviceNameByPmuId?pmuId='+pmuId+'&deviceId='+deviceId+'&deviceName='+deviceName)


//电站批量授权
export const insertUserPsRoleBatch = (data) => service.post('service-psinfo/userPsRole/insertUserPsRoleBatch',data)

//电站批量授权是否已存在
export const isUserRole = (data) => service.post('service-psinfo/userPsRole/isUserRole',data)

//获取电站授权转让日志
export const getPsRoleLogPage = (psName,logType,userId,createTime,page,pageSize) => service.get('service-user/syslog/getPsRoleLogPage?psName='+psName+'&logType='+logType+'&userId='+userId+'&createTime='+createTime+'&page='+page+'&pageSize='+pageSize)


//电站控制命令查询
export const getPlcDetailByPsid = (psId,searchDate,currentPage,pageSize) =>service.get('service-device/pssys/getPlcDetailByPsid?currentPage='+currentPage+'&pageSize='+pageSize+'&searchDate='+searchDate+'&psId='+psId)

//根据用户ID、电站ID、采集器ID查询设备列表
export const getDeviceListSmartOMByPsId = (psId,languageId) => service.get('service-psinfo/psinfo/getDeviceListSmartOMByPsId?psId='+psId+'&languageId='+languageId)



//故障报表下载
export const getErrorCsv = (psId,searchDate,userId,deviceTypeId,languageId,dateType) =>service.get('service-warning/warning/getErrorCsv?userId='+userId+'&psId='+psId+'&searchDate='+searchDate+'&deviceTypeId='+deviceTypeId+'&languageId='+languageId+'&dateType='+dateType)

//设备离线报表下载
export const getOfflineCsv = (psId,searchDate,userId,deviceTypeId,languageId,dateType) =>service.get('service-warning/warning/getOfflineCsv?userId='+userId+'&psId='+psId+'&searchDate='+searchDate+'&deviceTypeId='+deviceTypeId+'&languageId='+languageId+'&dateType='+dateType)

//电站通讯异常报表下载
export const getAbnormalCsv = (psId,searchDate,userId,deviceTypeId,languageId,dateType) =>service.get('service-warning/warning/getAbnormalCsv?userId='+userId+'&psId='+psId+'&searchDate='+searchDate+'&deviceTypeId='+deviceTypeId+'&languageId='+languageId+'&dateType='+dateType)

//电站阈值报警报表下载
export const getThresholdCsv = (psId,searchDate,userId,deviceTypeId,languageId,dateType) =>service.get('service-warning/warning/getThresholdCsv?userId='+userId+'&psId='+psId+'&searchDate='+searchDate+'&deviceTypeId='+deviceTypeId+'&languageId='+languageId+'&dateType='+dateType)


//故障屏蔽列表
export const queryFailureMaskDetailsPage = (userId,psId,maskType,failureName,searchDate,currentPage,pageCount,languageId) =>service.get('service-warning/failureMask/queryFailureMaskDetailsPage?currentPage='+currentPage+'&pageCount='+pageCount+'&userId='+userId+'&failureName='+failureName+'&psId='+psId+'&searchDate='+searchDate+'&maskType='+maskType+'&languageId='+languageId)

//取消故障屏蔽列表
export const cancelMask = id =>service.post('service-warning/failureMask/cancelMask?id='+id)


//获取故障屏蔽列表
export const getMaskErrorCsv = (currentPage,pageCount,deviceId,protocol,emsg,beginDate,endDate) =>service.get('service-warning/warning/getMaskErrorCsv?currentPage='+currentPage+'&pageCount='+pageCount+'&deviceId='+deviceId+'&protocol='+protocol+'&emsg='+emsg+'&beginDate='+beginDate+'&endDate='+endDate)


//屏蔽故障
export const maskFailure = (maskInfo) =>service.post('service-warning/failureMask/maskFailure',maskInfo)


//获取阈值报警设置的设备类型列表
export const queryDeviceType = () => service.get('service-warning/thresholdType/queryDeviceType')


//获取阈值报警设置的设备类型的参数列表
export const queryThresholdType = (deviceTypeId) => service.get('service-warning/thresholdType/queryThresholdType?deviceTypeId='+deviceTypeId)

//获取阈值报警设置的设备类型的参数列表
export const queryThresholdMath = () => service.get('service-warning/thresholdMath/queryThresholdMath?thresholdTypeId=')

//保存阈值报警设置
export const saveThreshold = (data) => service.post('service-warning/threshold/saveThreshold',data)

//获取阈值报警列表
export const queryThresholdList = (userId,psId,status,page,pageSize) => service.get('service-warning/threshold/queryThresholdList?userId='+userId+'&psId='+psId+'&status='+status+'&page='+page+'&pageSize='+pageSize)

//删除阈值报警设置
export const deleteThreshold = (userId,psId,thresholdId) => service.get('service-warning/threshold/deleteThreshold?thresholdId='+thresholdId+'&userId='+userId+'&psId='+psId)

//阈值报警列表
export const getEquipmentThresholdDetailsByPsIds = (psId,searchDate,currentPage,pageCount,userId,deviceTypeId) =>service.get('service-warning/warning/getThresholdDetail?currentPage='+currentPage+'&pageCount='+pageCount+'&userId='+userId+'&psId='+psId+'&searchDate='+searchDate+'&deviceTypeId='+deviceTypeId)

//获取阈值报警日志列表
export const queryThresholdListPage = (userId,psId,status,createTime,page,pageSize) => service.get('service-warning/threshold/queryThresholdListPage?userId='+userId+'&psId='+psId+'&status='+status+'&createTime='+createTime+'&page='+page+'&pageSize='+pageSize)

//获取阈值报警日志
export const getThresholdLogPage = (psName,logType,userId,createTime,page,pageSize) => service.get('service-user/syslog/getThresholdLogPage?psName='+psName+'&logType='+logType+'&userId='+userId+'&createTime='+createTime+'&page='+page+'&pageSize='+pageSize)


