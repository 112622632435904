import { combineReducers } from 'redux-immutable';
import {reducre as chooseTypeReducer} from '../components/chooseType/store/index.js'
import {reducre as maskChooseTypeReducer} from '../components/blockChooseType/store/index.js'
import {reducre as calendarMonthReducer} from '../components/calendarMonth/store/index.js'
import {reducre as calendarYearReducer} from '../components/calendarYear/store/index.js'
import {reducre as windowAlarmReducer} from '../components/windowAlarm/store/index.js'
import {reducre as alarmDeviceTypeReducer} from '../components/deviceType/store/index.js'
import {reducre as failureTypeReducer} from '../components/failureType/store/index.js'
import {reducre as psInfoListReducer} from '../components/psInfoList/store/index.js'
import {reducre as failureListReducer} from '../components/failureList/store/index.js'
import {reducre as thresholdTableReducer} from '../components/thresholdTable/store/index.js'
const defaultCombine = combineReducers({
  maskChooseType:maskChooseTypeReducer,
  chooseType:chooseTypeReducer,
  calendarMonth:calendarMonthReducer,
  calendarYear:calendarYearReducer,
  windowAlarm:windowAlarmReducer,
  alarmDeviceType:alarmDeviceTypeReducer,
  failureType:failureTypeReducer,
  psInfoList:psInfoListReducer,
  failureList:failureListReducer,
  threshold:thresholdTableReducer
})
export default defaultCombine